import React from "react";
import { FaInstagram, FaUsers } from "react-icons/fa";
import { formatNumber, getCurrentDateTime } from "./FormatNumber";

const CompetitorComparison = ({ competitors }) => {
  // Find the maximum followers count for scaling the bar widths
  const maxFollowersCount = Math.max(
    ...competitors.map((c) => c.followers_count || 0)
  );

  // Sort competitors by followers count in descending order
  const sortedFollowers = competitors.sort(
    (a, b) => (b.followers_count || 0) - (a.followers_count || 0)
  );

  return (
    <div className="competitor-comparison">
      <h3>Follower comparison</h3>
      <small>{getCurrentDateTime()}</small>
      <div className="followers">
        <table>
          <thead>
            <tr>
              <th>
                <FaInstagram size={15} />
              </th>
              <th>
                <FaUsers size={15} />
              </th>
              <th className="table__desc">Followers</th>
            </tr>
          </thead>
          <tbody>
            {sortedFollowers.map((competitor, key) => {
              const followersCount = competitor.followers_count || 0;
              const username = competitor.username || "n/a";

              return (
                <tr key={key}>
                  <td className="username__td">{username}</td>
                  <td className="followers__td">
                    {formatNumber(followersCount)}
                  </td>
                  <td>
                    <div
                      className="bar"
                      style={{
                        width: `${(followersCount / maxFollowersCount) * 100}%`,
                      }}
                    ></div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div className="ai__container">
          <div className="ai__header">
            <h4>
              <strong>AI growth insight</strong>{" "}
              <span role="img" aria-label="lightbulb">
                💡
              </span>{" "}
            </h4>
          </div>
          <div className="ai__content">
            <p>Coming soon</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CompetitorComparison;
