import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { GET_USER_ACCOUNTS } from "../modules/queries";
import { formatNumber } from "../modules/Converter";

const GET_GROUPED_METRICS = gql`
  query GetGroupedMetrics($conditions: [MetricCondition]!) {
    groupedMetrics(conditions: $conditions) {
      accountId
      platform
      metric
      username
      data {
        nonBreakdown {
          date
          value
        }
        breakdowns {
          key
          values {
            date
            value
          }
        }
      }
    }
  }
`;

function Reports() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData,
  } = useQuery(GET_USER_ACCOUNTS);

  const conditions = accountsData?.getUserAccounts?.reduce((acc, account) => {
    account.availableMetrics.forEach((metric) => {
      acc.push({
        platform: account.platform,
        accountId: account.accountId,
        metric: metric,
      });
    });
    return acc;
  }, []);

  const {
    data: groupedMetricsData,
    loading: groupedMetricsLoading,
    error: groupedMetricsError,
  } = useQuery(GET_GROUPED_METRICS, {
    variables: { conditions },
    skip: !conditions,
  });

  const metricsData = groupedMetricsData?.groupedMetrics;

  // Filter and aggregate metrics data by date range
  const aggregatedMetrics = metricsData?.reduce((acc, platformMetrics) => {
    const key = `${platformMetrics.username} - ${platformMetrics.platform}`;
    // Initialize object for storing aggregated values by metric name
    acc[key] = acc[key] || {};

    // Handle nonBreakdown if it exists
    platformMetrics.data.nonBreakdown?.forEach((metric) => {
      if (
        new Date(metric.date) >= new Date(startDate) &&
        new Date(metric.date) <= new Date(endDate)
      ) {
        acc[key][platformMetrics.metric] =
          (acc[key][platformMetrics.metric] || 0) + metric.value;
      }
    });

    // Handle breakdowns if they exist
    platformMetrics.data.breakdowns?.forEach((breakdown) => {
      if (!["AD", "DEFAULT_DO_NOT_USE"].includes(breakdown.key)) {
        breakdown.values.forEach((value) => {
          if (
            new Date(value.date) >= new Date(startDate) &&
            new Date(value.date) <= new Date(endDate)
          ) {
            acc[key][platformMetrics.metric] =
              (acc[key][platformMetrics.metric] || 0) + value.value;
          }
        });
      }
    });

    return acc;
  }, {});

  if (accountsLoading || groupedMetricsLoading) return <div>Loading...</div>;
  if (accountsError || groupedMetricsError)
    return <div>Error loading data.</div>;

  return (
    <div className="dashboard">
      <div className="dashboard-content">
        <h1>Reports</h1>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Start Date"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="End Date"
        />
        {Object.entries(aggregatedMetrics).map(([userPlatform, metrics]) => (
          <div key={userPlatform}>
            <h2>{userPlatform}</h2>
            {Object.entries(metrics).map(([metric, totalValue], index) => (
              <p key={`${metric}-${index}`}>
                Metric: {metric}, Total Value: {formatNumber(totalValue)}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reports;
