import React, { createContext, useContext } from "react";

// Creating the context
const CookieContext = createContext();

// Hook to use the context easily
export const useCookie = () => useContext(CookieContext);

// Provider component
export const CookieProvider = ({ children }) => {
  const allCookies = parseCookies(document.cookie);

  const relevantCookies = {
    jwt: allCookies.gh_jaywetetkn,
    role: allCookies.gh_role,
    profile_picture: allCookies.gh_pp,
    name: allCookies.gh_name,
  };
  function parseCookies(cookieStr) {
    return cookieStr.split("; ").reduce((acc, pair) => {
      const [key, value] = pair.split("=");
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
  }

  return (
    <CookieContext.Provider value={relevantCookies}>
      {children}
    </CookieContext.Provider>
  );
};
