import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_USER_ACCOUNTS,
  GET_MEDIA,
  GET_METRICS,
} from "../modules/queries.js";
import "../style/App.css";
import CustomChart from "../modules/CustomChart.js";
import {
  convertMetricToModelName,
  generateColor,
} from "../modules/Converter.js";
import moment from "moment";

export default function Customize() {
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [alreadyFetchedError, setAlreadyFetchedError] = useState(false);
  const [aggregate, setAggregate] = useState({ span: "" });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isMonthlyDisabled, setIsMonthlyDisabled] = useState(false);
  const [isWeeklyDisabled, setIsWeeklyDisabled] = useState(false);
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [fetchedMetrics, setFetchedMetrics] = useState([]);
  const [media, setMedia] = useState([]);
  const [log, setLog] = useState(false);
  const [reels, setReels] = useState(false);
  const [carousels, setCarousels] = useState(false);
  const [image, setImage] = useState(false);
  const [dateRange, setDateRange] = useState({
    minDate: "",
    midDate: "",
    maxDate: "",
  });

  const updateDateRange = (metrics) => {
    if (metrics && metrics.length > 0) {
      // Filter out invalid dates and map to Date objects
      const validDates = metrics
        .map((item) => new Date(item.date))
        .filter((date) => !isNaN(date));

      if (validDates.length > 0) {
        const minDate = new Date(
          Math.min(...validDates.map((date) => date.getTime()))
        );
        const maxDate = new Date(
          Math.max(...validDates.map((date) => date.getTime()))
        );

        // Calculate midDate
        const midTime =
          minDate.getTime() + (maxDate.getTime() - minDate.getTime()) / 2;
        const midDate = new Date(midTime);

        setDateRange({
          minDate: minDate.toISOString().split("T")[0], // format as 'YYYY-MM-DD'
          maxDate: maxDate.toISOString().split("T")[0],
          midDate: midDate.toISOString().split("T")[0], // format as 'YYYY-MM-DD'
        });
      }
    }
  };

  useEffect(() => {
    updateDateRange(fetchedMetrics);
  }, [fetchedMetrics]);

  useEffect(() => {
    const today = new Date();
    const ninetyDaysAgo = new Date(today.setDate(today.getDate() - 90));

    // Format dates to YYYY-MM-DD for input[type=date]
    const formatDate = (date) => {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    };

    setStartDate(formatDate(ninetyDaysAgo));
    setEndDate(formatDate(new Date())); // Today's date formatted
  }, []);

  const [
    getMetrics,
    { loading: metricsLoading, data: metricsData, error: metricsError },
  ] = useLazyQuery(GET_METRICS);

  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData,
  } = useQuery(GET_USER_ACCOUNTS);

  // this will initally check for the users stripe account
  // if we don't find an account, we return null
  // then we will not render out the stripe account select

  // we will also base the "account" select on the platform

  const [
    getMedia,
    { loading: mediaLoading, data: mediaData, error: mediaError },
  ] = useLazyQuery(GET_MEDIA);

  const [fetchData, setFetchData] = useState({
    platform: "",
    account: null,
    metric: "",
  });

  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
    setFetchData({
      ...fetchData,
      platform: e.target.value,
      account: null,
      metric: "",
    });
    setAvailableMetrics([]);
  };
  const handleAccountChange = (e) => {
    const selectedAccountId = e.target.value;

    // Directly find the selected account using a generalized property
    let selectedAccount = accountsData?.getUserAccounts.find(
      (account) =>
        account.accountId === selectedAccountId &&
        account.platform === selectedPlatform
    );

    // Update the state with the selected account
    setFetchData({
      ...fetchData,
      account: selectedAccount || null,
      metric: "",
    });

    // Update available metrics based on the selected account
    setAvailableMetrics(selectedAccount?.availableMetrics || []);
  };

  const handleMetricChange = (e) => {
    // Set the selected metric
    setFetchData({ ...fetchData, metric: e.target.value });
  };

  const handleCheckboxChange = (accountId, metricName) => {
    // Toggle the isChecked property for the metric with accountId and metricName
    setFetchedMetrics((prevMetrics) =>
      prevMetrics.map((metricItem) => {
        if (
          metricItem.accountId === accountId &&
          metricItem.metric === metricName
        ) {
          return { ...metricItem, isChecked: !metricItem.isChecked };
        }
        return metricItem;
      })
    );
  };

  const handleMediaChange = (accountId, platform) => {
    // Toggle the showMedia property for the metric with accountId and platform
    setFetchedMetrics((prevMetrics) =>
      prevMetrics.map((metricItem) => {
        if (
          metricItem.accountId === accountId &&
          metricItem.platform === platform
        ) {
          return { ...metricItem, showMedia: !metricItem.showMedia };
        }
        return metricItem;
      })
    );
  };

  const handleAlreadyFetchError = () => {
    setAlreadyFetchedError(true);
    const timer = setTimeout(() => {
      setAlreadyFetchedError(false);
    }, 3000);

    // Cleanup function
    return () => clearTimeout(timer);
  };
  const handleFetch = async () => {
    const index = fetchedMetrics.length;
    const color = generateColor(index);

    try {
      if (fetchData.platform && fetchData.metric && fetchData.account) {
        const isMetricFetched = fetchedMetrics.some(
          (metric) =>
            metric.platform === fetchData.platform &&
            metric.accountId ===
              (fetchData.account.accountId || fetchData.account.accountId) &&
            metric.metric === fetchData.metric
        );

        if (isMetricFetched) {
          handleAlreadyFetchError();
          console.log("Metric already fetched, not fetching again.");
          return;
        }
        if (
          fetchData.platform.toLowerCase() === "instagram" ||
          fetchData.platform.toLowerCase() === "tiktok"
        ) {
          const isMediaFetched = media.some(
            (media) => media.accountId === fetchData.account.accountId
          );

          if (!isMediaFetched) {
            console.log(
              "Media not fetched, fetching now:",
              fetchData.account.accountId
            );

            const fetchedMedia = await getMedia({
              variables: {
                accountId: fetchData.account.accountId,
                platform: fetchData.platform,
              },
            });

            if (fetchedMedia.data.Media) {
              setMedia((prevMedia) => {
                // Map over each fetched media item and add the color property
                const newMediaItems = fetchedMedia.data.Media.map(
                  (mediaItem) => ({
                    ...mediaItem,
                    color: color, // Add the color property to each media item
                  })
                );

                // Combine the previous media items with the new media items
                return [...prevMedia, ...newMediaItems];
              });
            }
          }
        }

        const response = await getMetrics({
          variables: {
            platform: fetchData.platform,
            accountId: fetchData.account.accountId,
            metric: fetchData.metric,
          },
        });

        if (!response) {
          console.log("No response from server");
          return;
        }
        setFetchedMetrics((prevMetrics) => {
          // Combine the old and new metrics, avoiding duplicates

          const newMetric = {
            ...(response.data?.metrics || {}),
            color: color,
          };

          return [...prevMetrics, newMetric];
        });
      }
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };
  const handleRemoveMetric = (accountId, platform, metricName) => {
    setFetchedMetrics((prevMetrics) =>
      prevMetrics.filter(
        (metric) =>
          !(
            metric.accountId === accountId &&
            metric.platform === platform &&
            metric.metric === metricName
          )
      )
    );
  };

  const handleLogChange = (e) => {
    setLog(e.target.checked);
  };

  const handleAdsChange = (accountId, platform, metric) => {
    setFetchedMetrics((prevMetrics) =>
      prevMetrics.map((metricItem) => {
        if (
          metricItem.accountId === accountId &&
          metricItem.platform === platform &&
          metricItem.metric === metric
        ) {
          return { ...metricItem, showAds: !metricItem.showAds };
        }
        return metricItem;
      })
    );
  };
  const Spinner = () => (
    <div className="fetch-spinner">{/* Simple spinner using CSS */}</div>
  );

  const selectedMetrics = fetchedMetrics.filter((metric) => metric.isChecked);

  // Function to calculate date difference
  const getDateDifference = (start, end) => {
    return moment(end).diff(moment(start), "days");
  };

  // useEffect to handle disabling/enabling aggregation options
  useEffect(() => {
    const dateDiff = getDateDifference(startDate, endDate);

    if (dateDiff < 7) {
      setIsWeeklyDisabled(true);
      setIsMonthlyDisabled(true);
      if (aggregate.span === "weekly" || aggregate.span === "monthly") {
        setAggregate({ span: "daily" });
      }
    } else if (dateDiff < 28) {
      setIsWeeklyDisabled(false);
      setIsMonthlyDisabled(true);
      if (aggregate.span === "monthly") {
        setAggregate({ span: "weekly" });
      }
    } else {
      setIsWeeklyDisabled(false);
      setIsMonthlyDisabled(false);
    }
  }, [startDate, endDate, aggregate.span]);

  if (accountsLoading) return <p>Loading...</p>;
  if (accountsError) return <p>Error loading accounts</p>;

  console.log("Fetched media:", media);
  return (
    <div
      className="dashboard"
      style={{ marginTop: `${30 * fetchedMetrics.length}px` }}
    >
      <div className="customize">
        <div className="customize__fixed__header">
          <div className="fetch__data__form">
            <select
              defaultValue=""
              name="platform"
              onChange={handlePlatformChange}
            >
              <option value="" disabled hidden>
                Platform..
              </option>
              {[
                ...new Set(
                  accountsData?.getUserAccounts?.map(
                    (account) => account.platform
                  )
                ),
              ].map((platform) => (
                <option key={platform} value={platform}>
                  {platform.charAt(0).toUpperCase() + platform.slice(1)}
                </option>
              ))}
            </select>

            <select
              name="account"
              value={fetchData?.account?.accountId || ""}
              onChange={handleAccountChange}
              disabled={!selectedPlatform}
            >
              {/* Always present "Account.." as the first option */}
              <option value="" disabled>
                Account..
              </option>

              {/* Conditional rendering of accounts based on the selectedPlatform */}
              {accountsData?.getUserAccounts
                ?.filter((account) => account.platform === selectedPlatform)
                .reduce((acc, current) => {
                  const x = acc.find(
                    (item) => item.username === current.username
                  );
                  if (!x) {
                    return acc.concat([current]);
                  } else {
                    return acc;
                  }
                }, [])
                .map((account) => (
                  <option key={account.accountId} value={account.accountId}>
                    {account.username}
                  </option>
                ))}
            </select>

            <select
              name="metric"
              defaultValue=""
              value={fetchData.metric}
              onChange={handleMetricChange}
              disabled={!fetchData.account}
            >
              <option value="" disabled hidden>
                Metric..
              </option>
              {availableMetrics.map((metric, index) => (
                <option key={index} value={metric}>
                  {metric}
                </option>
              ))}
            </select>

            <button onClick={handleFetch} className="button-with-spinner">
              {metricsLoading ? <Spinner /> : "ADD TO SET ⚡️"}
            </button>
            {alreadyFetchedError && (
              <div className="error">
                <p>Data already in set.</p>
              </div>
            )}
          </div>
          <div className="available__data">
            <table className="data-table">
              <thead>
                <tr className="data-header">
                  <th>Color</th>
                  <th>Platform</th>
                  <th>Account</th>
                  <th>Metric</th>
                  <th>Available from</th>
                  <th>Available until</th>
                  <th>Ads</th>
                  <th>Media</th>
                  <th>Display</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {fetchedMetrics.length > 0 ? (
                  fetchedMetrics.map((data, index) => (
                    <tr key={index} className="data-row">
                      <td className="data">
                        <div
                          style={{
                            backgroundColor: data.color,
                            width: "14px",
                            height: "14px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </td>
                      <td>{data.platform}</td>
                      <td>{data.username}</td>
                      <td>{data.metric}</td>
                      <td>{data.startDate?.split("T")[0]}</td>
                      <td>{data.endDate?.split("T")[0]}</td>
                      <td>
                        {data.ads ? (
                          <input
                            type="checkbox"
                            checked={data.showAds}
                            onChange={() =>
                              handleAdsChange(
                                data.accountId,
                                data.platform,
                                data.metric
                              )
                            }
                          />
                        ) : (
                          <input type="checkbox" checked={false} disabled />
                        )}
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={data.showMedia}
                          onChange={() =>
                            handleMediaChange(
                              data.accountId,
                              data.platform,
                              data.metric
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={data.isChecked}
                          onChange={() =>
                            handleCheckboxChange(data.accountId, data.metric)
                          }
                        />
                      </td>
                      <td
                        className="bin"
                        onClick={() =>
                          handleRemoveMetric(
                            data.accountId,
                            data.platform,
                            data.metric
                          )
                        }
                      >
                        🗑️
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="data-row">
                    <td className="data">
                      <div
                        style={{
                          backgroundColor: "grey",
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </td>
                    <td>-no data-</td>
                    <td>-no data-</td>
                    <td>-no data-</td>
                    <td>-no data-</td>
                    <td>-no data-</td>
                    <td>
                      <input type="checkbox" checked={true} disabled={true} />
                    </td>
                    <td>
                      <input type="checkbox" checked={true} disabled={true} />
                    </td>
                    <td>
                      {" "}
                      <input type="checkbox" checked={true} disabled={true} />
                    </td>
                    <td>🗑️</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="customize__chart__filter">
            <div className="chart__style">
              <small>Log</small>
              <input checked={log} onChange={handleLogChange} type="checkbox" />
            </div>
            {/* <div className="chart__style">
              <small>Images</small>
              <input
                type="checkbox"
                name="image"
                checked={image}
                onChange={handleMediaChange}
              />
            </div>
            <div className="chart__style">
              <small>Carousels</small>
              <input
                type="checkbox"
                name="carousels"
                checked={carousels}
                onChange={handleMediaChange}
              />
            </div>
            <div className="chart__style">
              <small>Reels</small>
              <input
                type="checkbox"
                name="reels"
                checked={reels}
                onChange={handleMediaChange}
              />
            </div> */}
            {/* <select
              name="aggregate"
              value={aggregate.span}
              onChange={handleAggregateChange}
            >
              <option value="" disabled hidden>
                Aggregate
              </option>
              <option value="daily">Daily</option>
              <option value="weekly" disabled={isWeeklyDisabled}>
                Weekly
              </option>
              <option value="monthly" disabled={isMonthlyDisabled}>
                Monthly
              </option>
            </select> */}
            <input
              name="startDate"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              name="endDate"
              type="date"
              max={dateRange.maxDate}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="customize__chart">
          <CustomChart
            data={selectedMetrics}
            startDate={startDate}
            endDate={endDate}
            log={log}
            mediaItem={media}
          />
        </div>
      </div>
    </div>
  );
}
