import React, { useState, useEffect } from "react";
import { GET_USER_ACCOUNTS, UPLOAD_USER_MUTATION } from "../modules/queries.js";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import "../style/App.css";
import api from "../modules/Api.js";
import { useCookie } from "../modules/CookieContext.js";

export default function Invite() {
  const { name } = useCookie();
  const [role, setRole] = useState("clientAdmin");
  const [fieldError, setFieldError] = useState("");
  const [uploadUserError, setUploadUserError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [accounts, setAccounts] = useState([{ id: 1, value: "" }]);
  const [status, setStatus] = useState("free");
  const [userEmail, setUserEmail] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [availableAccounts, setAvailableAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData,
  } = useQuery(GET_USER_ACCOUNTS);
  const [
    createUser,
    {
      data: createUserData,
      loading: createUserLoadings,
      error: createUserError,
    },
  ] = useMutation(UPLOAD_USER_MUTATION);

  const sendEmail = async () => {
    try {
      if (!userEmail || !name) {
        return;
      }
      const response = await api.post("/route/invite/send", {
        email: userEmail,
        invitor: name,
      });
      if (response.status !== 200) {
        console.log("Error sending email on status:", response.status);

        return;
      }
    } catch (error) {
      console.log("Error sending email on status:", error);
    }
  };

  useEffect(() => {
    if (createUserData && !createUserLoadings && !createUserError) {
      try {
        sendEmail();
        setUploadSuccess(true);
        setTimeout(() => {
          setUploadSuccess(false);
        }, 3000);
      } catch (error) {
        console.log("Error sending email on status:", error);
      }
    }

    if (createUserError) {
      console.log("Error creating user:", createUserError.message); // Log specific error message
      setUploadUserError(createUserError.message); // Set specific error message
      setTimeout(() => {
        setUploadUserError("");
      }, 3000);
    }
  }, [createUserData, createUserLoadings, createUserError]);

  const handleUpload = () => {
    if (!userEmail || !role || !status || !userCompany || !selectedAccounts) {
      setFieldError("Please fill in all fields");
      setTimeout(() => {
        setFieldError("");
      }, 3000);
      return;
    }
    const accountsArray = selectedAccounts.map((acc) => acc.accountId);
    createUser({
      variables: {
        email: userEmail,
        roles: role,
        status: status,
        company: userCompany,
        igAccounts: accountsArray,
      },
    });
  };

  console.log("accounts", accounts);
  console.log("selectedAccounts", selectedAccounts);

  useEffect(() => {
    if (accountsError) {
      console.error("GraphQL Error:", accountsError);
    }
    if (accountsData) {
      setAvailableAccounts(accountsData.getIgAccounts);
    }
  }, [accountsData, accountsError]);

  const addAccount = () => {
    const newId = accounts.length + 1;
    setAccounts([...accounts, { id: newId, value: "" }]);
    // No need to add to selectedAccounts here as it will be added upon selection
  };

  const removeAccount = (id) => {
    setAccounts(accounts.filter((account) => account.id !== id));
    setSelectedAccounts(
      selectedAccounts.filter((account) => account.id !== id)
    );
  };

  const handleAccountChange = (accountId, event) => {
    console.log("event.target.value", event.target.value);
    console.log("accountId", accountId);

    const newAccountId = event.target.value;
    setSelectedAccounts((prevSelectedAccounts) => {
      const updatedAccounts = prevSelectedAccounts.filter(
        (acc) => acc.id !== accountId
      );
      updatedAccounts.push({ id: accountId, accountId: newAccountId });
      return updatedAccounts;
    });
  };
  const Spinner = () => (
    <div className="fetch-spinner">{/* Simple spinner using CSS */}</div>
  );

  return (
    <div className="dashboard">
      <h2>Invite user</h2>
      <p>
        The user will be able to see all the available data for the accounts you
        apply.
      </p>
      <div className="privileges">
        <input
          type="email"
          name="user_email"
          placeholder="Facebook email.."
          onChange={(e) => setUserEmail(e.target.value)}
        />
        {/* <select onChange={(e) => setRole(e.target.value)} defaultValue="">
          <option value="" disabled hidden>
            --select role--
          </option>
          <option value="superUser">Supser User (GH only)</option>
          <option value="superCreator">Super Creator (GH only)</option>
          <option value="clientAdmin">
            Client Admin (head of client account)
          </option>
          <option value="clientCreator">Client Creator</option>
        </select> */}

        {role.length > 0 && role !== "superUser" && role !== "superCreator" && (
          <input
            type="text"
            name="company"
            placeholder="Company.."
            onChange={(e) => setUserCompany(e.target.value)}
          />
        )}
        {role.length > 0 && role !== "superUser" && (
          <>
            {accounts.map((account, index) => (
              <div key={account.id} className="privileges__accounts">
                <select
                  onChange={(e) => handleAccountChange(account.id, e)}
                  value={
                    selectedAccounts.find((acc) => acc.id === account.id)
                      ?.accountId || ""
                  }
                >
                  <option value="">Select account</option>
                  {availableAccounts?.map((acc) => (
                    <option
                      key={acc.instagramAccountId}
                      value={acc.instagramAccountId}
                    >
                      {acc.name}
                    </option>
                  ))}
                </select>
                {index > 0 && (
                  <img
                    key={index}
                    onClick={() => removeAccount(account.id)}
                    src="./black-02.png"
                    alt="minus-btn"
                  />
                )}
              </div>
            ))}
            {accounts.length <= availableAccounts.length && (
              <div onClick={addAccount} className="add__button">
                <img src="./black-03.png" alt="plus" />
                <p>add more accounts</p>
              </div>
            )}
          </>
        )}

        <button
          onClick={handleUpload}
          className="button-with-spinner upload__button"
        >
          {createUserLoadings ? <Spinner /> : "SEND INVITE ⚡️"}
        </button>
        {fieldError && <p className="error">{fieldError}</p>}
        {uploadSuccess && <p className="success">User created successfully!</p>}
        {uploadUserError && <p className="error">{uploadUserError.message}</p>}
      </div>
      {/* <h2>Manage Accounts</h2>
      <div className="privileges">
        <select
          onChange={handleAccountChange}
          value={selectedAccounts.instagramAccountId || ""}
        >
          <option value="">Select an account</option>
          {availableAccounts?.map((account) => (
            <option
              key={account.instagramAccountId}
              value={account.instagramAccountId}
            >
              {account.name}
            </option>
          ))}
        </select>
        <select onChange={(e) => setStatus(e.target.value)} defaultValue="">
          <option value="" disabled hidden>
            --select status--
          </option>
          <option value="active">Active</option>
          <option value="deactive">Deactive</option>
        </select>
        <div className="get__button">
          <button onClick={""}>UPDATE</button>
        </div>
      </div> */}
    </div>
  );
}
