import React, { useState } from "react";
import {
  FaRegHeart,
  FaRegComment,
  FaImages,
  FaInstagram,
} from "react-icons/fa";
import { formatNumber } from "./FormatNumber";
import moment from "moment-timezone";

const Calculator = ({ competitors }) => {
  const periodOptions = {
    30: {
      start: moment().subtract(30, "days").startOf("day"),
      end: moment().startOf("day"),
    },
    60: {
      start: moment().subtract(60, "days").startOf("day"),
      end: moment().startOf("day"),
    },
    90: {
      start: moment().subtract(3, "months").startOf("day"),
      end: moment().startOf("day"),
    },
    180: {
      start: moment().subtract(6, "months").startOf("day"),
      end: moment().startOf("day"),
    },
  };

  const [selectedPeriodKey, setSelectedPeriodKey] = useState("30");
  const [selectedPeriod, setSelectedPeriod] = useState(
    periodOptions[selectedPeriodKey]
  );
  const [multiplier, setMultiplier] = useState(1);
  const [qualityMultiplier, setQualityMultiplier] = useState(1);
  // Helper function to parse date strings
  const parseDate = (dateString) => new Date(dateString);

  const calculateEngagement = (mediaArray, period) => {
    let engagement = { likes: 0, comments: 0 };
    // Use the start and end properties directly, assuming they're Moment objects
    const periodStartDate = period.start.toDate(); // Convert start Moment object to JavaScript Date
    const periodEndDate = period.end.toDate(); // Convert end Moment object to JavaScript Date

    mediaArray.forEach((media) => {
      const mediaDate = parseDate(media.timestamp);

      // Check if the media date is within the selected period
      if (mediaDate >= periodStartDate && mediaDate <= periodEndDate) {
        engagement.likes += Number(media.likes) || 0;
        engagement.comments += Number(media.comments) || 0;
      }
    });

    return engagement;
  };

  // Adapted function to use moment for date comparison
  const calculateEngagementAndMedia = (mediaArray, { start, end }) => {
    let engagement = { likes: 0, comments: 0, mediaCount: 0 };

    mediaArray.forEach((media) => {
      const mediaDate = moment(media.timestamp, "YYYY-MM-DD HH:mm:ss");
      if (mediaDate.isBetween(start, end, undefined, "[]")) {
        engagement.likes += Number(media.likes) || 0;
        engagement.comments += Number(media.comments) || 0;
        engagement.mediaCount += 1;
      }
    });

    return engagement;
  };

  const calculateTotalEngagement = (comp) => {
    const reelsEngagement = calculateEngagement(comp.reels, selectedPeriod);
    const photosEngagement = calculateEngagement(comp.photos, selectedPeriod);
    const carouselsEngagement = calculateEngagement(
      comp.carousels,
      selectedPeriod
    );

    let engagement =
      reelsEngagement.likes +
      reelsEngagement.comments +
      photosEngagement.likes +
      photosEngagement.comments +
      carouselsEngagement.likes +
      carouselsEngagement.comments;

    if (comp.isMainAccount) {
      engagement *= multiplier * qualityMultiplier;
    }

    console.log("carouselsEngagement", carouselsEngagement);
    console.log("photosEngagement", photosEngagement);
    console.log("reelsEngagement", reelsEngagement);
    console.log("engagement", engagement);

    return engagement;
  };

  const calculateMaxEngagement = () => {
    const maxEngagement = Math.max(
      ...competitors.map((comp) => calculateTotalEngagement(comp))
    );

    console.log("maxEngagement", maxEngagement);
    return maxEngagement;
  };

  const sortedCompetitors = React.useMemo(() => {
    return [...competitors].sort((a, b) => {
      // Calculate total engagement for each competitor

      const engagementA = calculateEngagementAndMedia(
        [...a.reels, ...a.photos, ...a.carousels],
        selectedPeriod
      );

      const totalEngagementA =
        (engagementA.likes + engagementA.comments) *
        (a.isMainAccount ? multiplier * qualityMultiplier : 1);

      const engagementB = calculateEngagementAndMedia(
        [...b.reels, ...b.photos, ...b.carousels],
        selectedPeriod
      );

      const totalEngagementB =
        (engagementB.likes + engagementB.comments) *
        (b.isMainAccount ? multiplier * qualityMultiplier : 1);

      return totalEngagementB - totalEngagementA;
    });
  }, [competitors, multiplier, qualityMultiplier, selectedPeriod]);

  const maxEngagement = calculateMaxEngagement();
  console.log("maxEngagement", maxEngagement);

  const handlePeriodChange = (event) => {
    const newPeriodKey = event.target.value;
    setSelectedPeriodKey(newPeriodKey);
    setSelectedPeriod(periodOptions[newPeriodKey]);
  };

  const handleMultiplierChange = (event) => {
    setMultiplier(Number(event.target.value));
  };

  const handleQualityChange = (event) => {
    setQualityMultiplier(Number(event.target.value));
  };

  return (
    <div className="competitor-comparison competitor-calculator">
      <div className="calculator__header">
        <h1>GROWTH CALCULATOR</h1>
        <h2>Visualize your growth | pass your competitors</h2>
      </div>
      <div className="table__header lonley__date">
        <div className="date__range">
          <select value={selectedPeriodKey} onChange={handlePeriodChange}>
            <option value="30">Last 30 days</option>
            <option value="60">Last 60 days</option>
            <option value="90">Last 3 months</option>
            <option value="180">Last half year</option>
          </select>
        </div>
      </div>

      <div className="calculator">
        <div className="multiplier__selector">
          <div className="slider__container">
            <h2>Media frequency</h2>
            <label
              style={{ fontWeight: "bold", color: "#a100c2" }}
              htmlFor="mediaMultiplier"
            >
              {multiplier}x
            </label>
            <input
              id="mediaMultiplier"
              type="range"
              min="1"
              max="5"
              value={multiplier}
              onChange={handleMultiplierChange}
              step="1"
              className="media__slider"
            />
            <small className="small__descriptive">
              Drag to increase media per selected period.
            </small>
          </div>
          <div className="slider__container">
            <h2>Engaging content</h2>

            <label
              style={{ fontWeight: "bold", color: "#fbde51" }}
              htmlFor="qualityMultipler"
            >
              {qualityMultiplier}x
            </label>
            <input
              type="range"
              min="1"
              max="5"
              value={qualityMultiplier}
              step="1"
              onChange={handleQualityChange}
              className="quality__slider"
            />
            <small className="small__descriptive">
              Drag to make your content more engaging.
            </small>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <FaInstagram size={15} />
              </th>
              <th>
                <FaImages size={15} />
              </th>
              <th>
                <FaRegHeart className="engagement__th" size={15} />
                <FaRegComment className="engagement__th" size={15} />
              </th>
              <th className="table__desc">Total engagement</th>
            </tr>
          </thead>
          <tbody>
            {sortedCompetitors.map((competitor, key) => {
              const username = competitor.username;
              const totalEngagementData = calculateEngagementAndMedia(
                [
                  ...competitor.reels,
                  ...competitor.photos,
                  ...competitor.carousels,
                ],
                selectedPeriod
              );

              let totalPosts = totalEngagementData.mediaCount;
              let totalLikesComments =
                totalEngagementData.likes + totalEngagementData.comments;

              if (competitor.isMainAccount) {
                totalPosts *= multiplier;
                totalLikesComments *= multiplier * qualityMultiplier;
              }

              const barWidth = (totalLikesComments / maxEngagement) * 100;

              return (
                <tr
                  key={key}
                  style={{
                    background: competitor.isMainAccount ? "#e0ecef" : "",
                  }}
                >
                  <td className="username__td">{username}</td>
                  <td className="followers__td">{formatNumber(totalPosts)}</td>
                  <td className="followers__td">
                    {formatNumber(Math.round(totalLikesComments))}
                  </td>

                  <td className="media-bar-holder">
                    <div
                      className="media-bar"
                      style={{
                        width: `${barWidth}%`,
                        backgroundColor: "#4b8d91",
                        height: "15px",
                        borderRadius: "10px",
                      }}
                    ></div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calculator;
