import {
  FaHome,
  FaPaperPlane,
  FaSignOutAlt,
  FaRocket,
  FaCube,
  FaLock,
  FaPlug,
  FaDatabase,
  FaFilm,
  FaFilter,
  FaSeedling,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../style/NavBar.css";
import api from "../modules/Api";
import { useCookie } from "../modules/CookieContext.js";

const handleLogout = async () => {
  console.log("Logging out");

  try {
    const respone = await api.post("/route/logout");
    if (respone.status !== 200) {
      console.log("Response status not 200");
      return;
    }
    window.location.href = "/";
  } catch (error) {
    console.log("Error logging out:", error);
  }
};

export const SuperUserMenu = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [partOfDay, setPartOfDay] = useState("");
  const [surprise, setSurprise] = useState("");

  useEffect(() => {
    const hour = new Date().getHours();
    let message = "";
    let surpriseMessage = "";
    let part = "";

    if (hour < 12) {
      message = `Good morning 🌤️`;
      surpriseMessage = "Coffee break?";
      part = "morning";
    } else if (hour < 18) {
      message = `Good afternoon 🌼`;
      surpriseMessage = "Go for a walk?";
      part = "afternoon";
    } else {
      message = `Good evening 🌙`;
      surpriseMessage = "Time to relax?";
      part = "evening";
    }

    setWelcomeMessage(message);
    setPartOfDay(part);
    setSurprise(surpriseMessage);
  });

  return (
    <nav className="navbar">
      <div className="navbar-top">gh.</div>

      <div className="nav-items">
        {/* <Link
          to="/gh-dash"
          className={`gh-nav ${isActive("/gh-dash") ? "nav-item-active" : ""}`}
        >
          <FaLeaf />
          <span>Growhouse Stats</span>
        </Link> */}
        <Link
          to="/dash"
          className={`nav-item ${isActive("/dash") ? "nav-item-active" : ""}`}
        >
          <FaHome />
          <span>Dashboard</span>
        </Link>
        <Link
          to="/reports"
          className={`nav-item ${
            isActive("/reports") ? "nav-item-active" : ""
          }`}
        >
          <FaDatabase />
          <span>Reports</span>
        </Link>
        <Link
          to="/seed"
          className={`nav-item ${isActive("/seed") ? "nav-item-active" : ""}`}
        >
          <FaSeedling />
          <span>Tracking</span>
        </Link>
        <Link
          to="/funnels"
          className={`nav-item ${
            isActive("/funnels") ? "nav-item-active" : ""
          }`}
        >
          <FaFilter />
          <span>Funnels</span>
        </Link>
        {/* <Link
          to="/insights"
          className={`nav-item ${
            isActive("/insights") ? "nav-item-active" : ""
          }`}
        >
          <FaHamsa />
          <span>Insights</span>
        </Link> */}
        <Link
          to="/customize"
          className={`nav-item ${
            isActive("/customize") ? "nav-item-active" : ""
          }`}
        >
          <FaCube />
          <span>Discovery</span>
        </Link>
        {/* <Link
          to="/media"
          className={`nav-item ${isActive("/media") ? "nav-item-active" : ""}`}
        >
          <FaFilm />
          <span>Media</span>
        </Link> */}
        {/* <Link
          to="/playground"
          className={`nav-item ${
            isActive("/playground") ? "nav-item-active" : ""
          }`}
        >
          <FaRocket />
          <span>Beta</span>
        </Link> */}
        {/* <Link
          to="/competitors"
          className={`nav-item ${
            isActive("/competitors") ? "nav-item-active" : ""
          }`}
        >
          <FaHammer />
          <span>Set Goal</span>
        </Link> */}
        {/* <Link
          to="/projections"
          className={`nav-item ${
            isActive("/projections") ? "nav-item-active" : ""
          }`}
        >
          <FaRocket />
          <span>Projections</span>
        </Link> */}
        <Link
          to="/integrations"
          className={`nav-item ${
            isActive("/integrations") ? "nav-item-active" : ""
          }`}
        >
          <FaPlug />
          <span>Intergrations</span>
        </Link>
        <Link
          to="/privileges"
          className={`nav-item ${
            isActive("/privileges") ? "nav-item-active" : ""
          }`}
        >
          <FaLock />
          <span>Privileges</span>
        </Link>
        {/* <Link
          to="/settings"
          className={`nav-item ${
            isActive("/settings") ? "nav-item-active" : ""
          }`}
        >
          <FaCog />
          <span>Settings</span>
        </Link> */}
      </div>

      <div className="navbar-bottom">
        <div className="user-profile">
          <div className="user-info"></div>
          <Link onClick={handleLogout}>
            <FaSignOutAlt />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export const ClientAdminMenu = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [partOfDay, setPartOfDay] = useState("");
  const [surprise, setSurprise] = useState("");

  useEffect(() => {
    const hour = new Date().getHours();
    let message = "";
    let surpriseMessage = "";
    let part = "";

    if (hour < 12) {
      message = `Good morning 🌤️`;
      surpriseMessage = "Coffee break?";
      part = "morning";
    } else if (hour < 18) {
      message = `Good afternoon 🌼`;
      surpriseMessage = "Go for a walk?";
      part = "afternoon";
    } else {
      message = `Good evening 🌙`;
      surpriseMessage = "Time to relax?";
      part = "evening";
    }

    setWelcomeMessage(message);
    setPartOfDay(part);
    setSurprise(surpriseMessage);
  });

  return (
    <nav className="navbar">
      <div className="navbar-top">gh.</div>

      <div className="nav-items">
        {/* <Link
          to="/gh-dash"
          className={`gh-nav ${isActive("/gh-dash") ? "nav-item-active" : ""}`}
        >
          <FaLeaf />
          <span>Growhouse Stats</span>
        </Link> */}
        <Link
          to="/dash"
          className={`nav-item ${isActive("/dash") ? "nav-item-active" : ""}`}
        >
          <FaHome />
          <span>Dashboard</span>
        </Link>
        <Link
          to="/reports"
          className={`nav-item ${
            isActive("/reports") ? "nav-item-active" : ""
          }`}
        >
          <FaDatabase />
          <span>Reports</span>
        </Link>
        {/* <Link
          to="/insights"
          className={`nav-item ${
            isActive("/insights") ? "nav-item-active" : ""
          }`}
        >
          <FaHamsa />
          <span>Insights</span>
        </Link> */}
        <Link
          to="/customize"
          className={`nav-item ${
            isActive("/customize") ? "nav-item-active" : ""
          }`}
        >
          <FaCube />
          <span>Discovery</span>
        </Link>

        {/* <Link
          to="/playground"
          className={`nav-item ${
            isActive("/playground") ? "nav-item-active" : ""
          }`}
        >
          <FaRocket />
          <span>Competitors</span>
        </Link> */}
        {/* <Link
          to="/competitors"
          className={`nav-item ${
            isActive("/competitors") ? "nav-item-active" : ""
          }`}
        >
          <FaHammer />
          <span>Set Goal</span>
        </Link> */}
        {/* <Link
          to="/projections"
          className={`nav-item ${
            isActive("/projections") ? "nav-item-active" : ""
          }`}
        >
          <FaRocket />
          <span>Projections</span>
        </Link> */}
        <Link
          to="/integrations"
          className={`nav-item ${
            isActive("/integrations") ? "nav-item-active" : ""
          }`}
        >
          <FaPlug />
          <span>Intergrations</span>
        </Link>
        <Link
          to="/invite"
          className={`nav-item ${isActive("/invite") ? "nav-item-active" : ""}`}
        >
          <FaPaperPlane />
          <span>Invite</span>
        </Link>
        {/* <Link
          to="/privileges"
          className={`nav-item ${
            isActive("/privileges") ? "nav-item-active" : ""
          }`}
        >
          <FaLock />
          <span>Privileges</span>
        </Link> */}
        {/* <Link
          to="/settings"
          className={`nav-item ${
            isActive("/settings") ? "nav-item-active" : ""
          }`}
        >
          <FaCog />
          <span>Settings</span>
        </Link> */}
      </div>

      <div className="navbar-bottom">
        <div className="user-profile">
          <div className="user-info"></div>
          <Link onClick={handleLogout}>
            <FaSignOutAlt />
          </Link>
        </div>
      </div>
    </nav>
  );
};
