import React from "react";
import "../style/App.css";

export default function Terms() {
  return (
    <div
      className="privacy__div"
      style={{ backgroundColor: "white", color: "black", padding: "20px" }}
    >
      <h2>Terms and Conditions for [https://grow.growhouse.co]</h2>
      <h4>Introduction</h4>
      <p>
        "These Terms and Conditions govern your use of [https://growhouse.co]
        and [https://grow.growhouse.co] (hereafter referred to as 'the Website')
        and its content. By accessing or using the Website, you agree to be
        bound by these terms."
      </p>

      <h4>Privacy Policy</h4>
      <p>
        "Please review our Privacy Policy at
        [https://grow.growhouse.co/privacy], which also governs your visit to
        the Website, to understand our practices regarding your personal
        information and data protection rights under GDPR and other applicable
        laws."
      </p>

      <h4>Use of Website</h4>
      <p>
        "The Website and its content are intended for visitors, users, and our
        customers of [https://growhouse.co]. You may not use this website for
        any illegal or unauthorized purpose."
      </p>
      <p>
        "Similarly, [https://grow.growhouse.co] is designed for its visitors,
        users, and customers, subject to the same restrictions against illegal
        or unauthorized use."
      </p>

      <h4>Intellectual Property Rights</h4>
      <p>
        "The content on the Website, including but not limited to text,
        graphics, logos, and images, is the property of Growhouse or is used
        with permission. This content is protected by copyright and other
        intellectual property laws, including client logos and publicly posted
        posts used under agreement."
      </p>

      <h4>Limitation of Liability</h4>
      <p>
        "In no event will [Growhouse], its affiliates, or their respective
        officers, directors, employees, or agents be liable for any indirect,
        incidental, special, consequential, or punitive damages resulting from
        your use of or inability to use the Website."
      </p>

      <h4>Disclaimer</h4>
      <p>
        "The Website and its content are provided on an 'as is' basis without
        any warranties of any kind, either express or implied, including but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, or non-infringement."
      </p>

      <h4>Indemnification</h4>
      <p>
        "You agree to indemnify, defend, and hold harmless [Growhouse], its
        affiliates, officers, directors, employees, and agents, from and against
        any claims arising out of your use of the Website or your violation of
        these Terms and Conditions."
      </p>

      <h4>Modification of Terms</h4>
      <p>
        "We reserve the right to modify these Terms and Conditions at any time.
        Your continued use of the Website after any such changes constitutes
        your acceptance of the new Terms and Conditions."
      </p>

      <h4>Data Protection</h4>
      <p>
        "We are committed to protecting the privacy and security of your
        personal data. Our practices are designed to be in compliance with the
        General Data Protection Regulation (GDPR) and applicable data protection
        laws. For detailed information on how we collect, use, and safeguard
        your personal data, and your rights regarding your data, please see our
        Privacy Policy."
      </p>

      <h4>Governing Law and Jurisdiction</h4>
      <p>
        "These Terms and Conditions are governed by the laws of Sweden, without
        regard to its conflict of law provisions. Any disputes arising under or
        in connection with these Terms shall be subject to the exclusive
        jurisdiction of the Swedish courts."
      </p>
    </div>
  );
}
