import React from "react";

export default function RequestAccess() {
  return (
    <div className="dashboard">
      <p>Sorry this page has no fancy style yet 🧑‍🎨</p>
      <p>
        If you want to request early beta access, plase contact{" "}
        <strong>felix@growhouse.co</strong>{" "}
      </p>
      <p>Have a nice day 🚀</p>
    </div>
  );
}
