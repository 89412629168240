import React, { useState, useEffect } from "react";
import {
  FaInstagram,
  FaRegHeart,
  FaRegComment,
  FaChartLine,
  FaSortAmountDown,
} from "react-icons/fa";
import moment from "moment-timezone";

import { formatNumber, getCurrentDateTime } from "./FormatNumber";

const EngagementCompairson = ({ competitors }) => {
  const [selectedPeriod, setSelectedPeriod] = useState("30");
  const [sortedCompetitors, setSortedCompetitors] = useState([]);
  const [sortOrder, setSortOrder] = useState("none");

  const filterMediaByDate = (mediaArray, days) => {
    const cutoffDate = moment().subtract(+days, "days");
    return mediaArray.filter((media) => {
      const mediaDate = moment(media.timestamp);
      return mediaDate.isAfter(cutoffDate);
    });
  };
  const calculateTotalEngagement = (mediaArray) => {
    return mediaArray.reduce((total, media) => {
      return total + (media.likes || 0) + (media.comments || 0);
    }, 0);
  };
  const calculateLikes = (mediaArray) => {
    return mediaArray.reduce((total, media) => total + (media.likes || 0), 0);
  };

  const calculateComments = (mediaArray) => {
    return mediaArray.reduce(
      (total, media) => total + (media.comments || 0),
      0
    );
  };

  const calculateGrowth = (current, previous) => {
    if (previous === 0) return "n/a";
    return ((current - previous) / previous) * 100;
  };
  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  useEffect(() => {
    let updatedSortedCompetitors = competitors.map((competitor) => {
      const currentData = [
        ...filterMediaByDate(competitor.reels, selectedPeriod),
        ...filterMediaByDate(competitor.carousels, selectedPeriod),
        ...filterMediaByDate(competitor.photos, selectedPeriod),
      ];

      let growth = "n/a";
      let currentTotalEngagement = null;
      let previousTotalEngagement = null;

      if (selectedPeriod !== "180") {
        const allDataForDoublePeriod = [
          ...filterMediaByDate(competitor.reels, selectedPeriod * 2),
          ...filterMediaByDate(competitor.carousels, selectedPeriod * 2),
          ...filterMediaByDate(competitor.photos, selectedPeriod * 2),
        ];

        const previousData = allDataForDoublePeriod.filter(
          (media) => !currentData.includes(media)
        );

        currentTotalEngagement = calculateTotalEngagement(currentData);
        previousTotalEngagement = calculateTotalEngagement(previousData);
        if (previousTotalEngagement > 0) {
          const growthValue = calculateGrowth(
            currentTotalEngagement,
            previousTotalEngagement
          );
          growth = isNaN(growthValue) ? "n/a" : `${growthValue.toFixed(0)}%`;
        }
      }

      return {
        ...competitor,
        currentTotalEngagement,
        previousTotalEngagement,
        growth,
      };
    });

    if (sortOrder !== "none") {
      updatedSortedCompetitors.sort((a, b) => {
        let growthA = parseGrowthValue(a.growth);
        let growthB = parseGrowthValue(b.growth);
        return sortOrder === "ascending"
          ? growthA - growthB
          : growthB - growthA;
      });
    }

    setSortedCompetitors(updatedSortedCompetitors);
  }, [sortOrder, selectedPeriod, competitors]);

  const parseGrowthValue = (growth) => {
    return growth === "n/a" ? 0 : parseFloat(growth);
  };

  const handleSortClick = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === "none") return "ascending";
      if (prevOrder === "ascending") return "descending";
      return "none";
    });
  };

  const maxEngagement = Math.max(
    ...sortedCompetitors.map((comp) => {
      const reelsFiltered = filterMediaByDate(comp.reels, selectedPeriod);
      const carouselsFiltered = filterMediaByDate(
        comp.carousels,
        selectedPeriod
      );
      const photosFiltered = filterMediaByDate(comp.photos, selectedPeriod);

      return calculateTotalEngagement([
        ...reelsFiltered,
        ...carouselsFiltered,
        ...photosFiltered,
      ]);
    })
  );

  return (
    <div className="competitor-comparison">
      <h3>Engagement</h3>
      <div className="table__header">
        <div className="color__engagement">
          <p>Likes</p>
          <p>Comments</p>
        </div>
        <div className="date__range">
          <select value={selectedPeriod} onChange={handlePeriodChange}>
            <option value="30">Last 30 days</option>
            <option value="60">Last 60 days</option>
            <option value="90">Last 3 months</option>
            <option value="180">Last half year</option>
          </select>
        </div>
      </div>
      <div className="followers">
        <table>
          <thead>
            <tr>
              <th>
                <FaInstagram size={15} />
              </th>
              <th>
                <FaRegHeart size={15} />
              </th>
              <th>
                <FaRegComment size={15} />
              </th>
              <th className="sort" onClick={handleSortClick}>
                <FaSortAmountDown size={15} color="#484848" />
              </th>
              <th className="table__desc">Total eng.</th>
            </tr>
          </thead>
          <tbody>
            {sortedCompetitors.map((competitor, key) => {
              const username = competitor.username || "n/a";

              const reelsFiltered = filterMediaByDate(
                competitor.reels,
                selectedPeriod
              );
              const carouselsFiltered = filterMediaByDate(
                competitor.carousels,
                selectedPeriod
              );
              const photosFiltered = filterMediaByDate(
                competitor.photos,
                selectedPeriod
              );

              const totalLikes = calculateLikes([
                ...reelsFiltered,
                ...carouselsFiltered,
                ...photosFiltered,
              ]);
              const totalComments = calculateComments([
                ...reelsFiltered,
                ...carouselsFiltered,
                ...photosFiltered,
              ]);

              // Updated renderMediaTypeBar function
              const renderMediaTypeBar = (likes, comments) => {
                const totalEngagement = likes + comments;
                const likesWidth =
                  totalEngagement > 0 ? (likes / totalEngagement) * 100 : 0;
                const commentsWidth =
                  totalEngagement > 0 ? (comments / totalEngagement) * 100 : 0;

                // Calculate the width of the bar as a percentage of maxEngagement
                const barWidth =
                  maxEngagement > 0
                    ? (totalEngagement / maxEngagement) * 100
                    : 0;

                return (
                  <div className="media-bar" style={{ width: `${barWidth}%` }}>
                    <div
                      className="likes-bar"
                      style={{
                        width: `${likesWidth}%`,
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    ></div>
                    <div
                      className="comments-bar"
                      style={{
                        width: `${commentsWidth}%`,
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    ></div>
                  </div>
                );
              };

              return (
                <tr key={key}>
                  <td className="username__td">{username}</td>
                  <td className="followers__td">{formatNumber(totalLikes)}</td>
                  <td className="followers__td">
                    {formatNumber(totalComments)}
                  </td>
                  <td
                    className="followers__td"
                    style={{
                      color:
                        competitor.growth === "n/a"
                          ? "black"
                          : competitor.growth < "0%"
                          ? "red"
                          : "green",
                    }}
                  >
                    {competitor.growth}
                  </td>
                  <td className="media-bar-holder">
                    {renderMediaTypeBar(totalLikes, totalComments)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <div className="ai__container">
        <div className="ai__header">
          <strong>AI engagement insight</strong> 💡
        </div>
        <div className="ai__content">
          <p>Coming soon</p>
        </div>
      </div> */}
    </div>
  );
};

export default EngagementCompairson;
