import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";

const currentUrl = window.location.href;

let baseUrl;
if (currentUrl.includes("grow.")) {
  baseUrl = "https://grow.growhouse.co"; // Development URL
} else {
  baseUrl = "https://growhousetest.com"; // Production URL
}
console.log("baseUrl:", baseUrl);

const httpLink = new HttpLink({
  uri: `${baseUrl}/api/graphql`, // Replace with your GraphQL endpoint
  credentials: "include", // Include cookies when sending requests
});

// Create the Apollo Client instance.
const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

// Export the ApolloProvider with the client.
export function ApolloClientProvider({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
