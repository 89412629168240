import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import FollowerGoalChart from "./FollowerGoalChart";
import ReachGrowthBar from "./ReachGrowthBar";
import { useQuery, useLazyQuery } from "@apollo/client";

const GoalCalculator = ({ mainAccount }) => {
  const [reachIncrease, setReachIncrease] = useState();
  const [reach, setReach] = useState();
  const [followerGain, setFollowerGain] = useState();
  const [profileViews, setProfileViews] = useState();
  const [calculate, setCalculate] = useState(false);
  const [RTP, setRTP] = useState();
  const [PTF, setPTF] = useState();
  const [followGain, setFollowGain] = useState();
  const [profileViewsGoal, setProfileViewsGoal] = useState();
  const [reachGoal, setReachGoal] = useState();
  const [followerGoal, setFollowerGoal] = useState();

  const maxFollowerGoal = mainAccount.followers_count * 2;

  function calculateMonthsUntilEndOfYear() {
    const now = moment();
    const endOfYear = moment().endOf("year");
    const monthsRemaining = endOfYear.diff(now, "months");
    return monthsRemaining;
  }

  const handleGoalChange = (e) => {
    setFollowerGoal(e.target.value);
  };

  const handleReachChange = (e) => {
    setReach(e.target.value);
  };
  const handleProfileViewsChange = (e) => {
    setProfileViews(e.target.value);
  };
  const handleFollowerGain = (e) => {
    setFollowerGain(e.target.value);
  };

  const calculateGoal = () => {
    try {
      const RTP = ((profileViews / reach) * 100).toFixed(2) + "%";
      const PTF = ((followerGain / profileViews) * 100).toFixed(2) + "%";
      const followGain = Math.floor(
        (followerGoal - mainAccount.followers_count) /
          calculateMonthsUntilEndOfYear()
      );
      const profileViewsGoal = Math.floor(
        (followerGoal - mainAccount.followers_count) /
          (followerGain / profileViews) /
          calculateMonthsUntilEndOfYear()
      );
      const reachGoal = Math.floor(
        (followerGoal - mainAccount.followers_count) /
          (followerGain / profileViews) /
          (profileViews / reach) /
          calculateMonthsUntilEndOfYear()
      );
      const reachIncrease = reachGoal - reach;
      setRTP(RTP);
      setPTF(PTF);
      setFollowGain(followGain);
      setProfileViewsGoal(profileViewsGoal);
      setReachGoal(reachGoal);
      setReachIncrease(reachIncrease);
      setCalculate(true);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="competitor-comparison competitor-calculator">
      <h1>YOUR FOLLOWER GOAL FOR 2024</h1>
      <div className="growth__grid">
        <div className="growth__goal__input">
          <h4>Followers goal 2024</h4>
          <input
            className="follower__input"
            onChange={handleGoalChange}
            placeholder="Followers goal.."
          />
          <h4>Enter your last 30 days organic reach</h4>
          <input
            type="number"
            onChange={handleReachChange}
            className="follower__input"
            placeholder="Organic reach.."
          />
          <h4>Enter your last 30 days profile views</h4>
          <input
            type="number"
            onChange={handleProfileViewsChange}
            className="follower__input"
            placeholder="Profile views.."
          />
          <h4>Enter your last 30 days net follow gain</h4>
          <input
            type="number"
            onChange={handleFollowerGain}
            className="follower__input"
            placeholder="Followers gained.."
          />
          <div className="calculate__button">
            <button onClick={calculateGoal}>CALCULATE</button>
          </div>
        </div>
        <div className="growth__goal__results">
          {calculate && (
            <>
              <div className="goal__chart__grid">
                <h2>You need: Monthly reach</h2>
                <h2>To get: Follower Projection</h2>
                <ReachGrowthBar
                  currentReach={+reach}
                  goalReach={+reachIncrease}
                />
                <FollowerGoalChart
                  followerGoal={followerGoal}
                  currentFollowers={mainAccount.followers_count}
                  followGoalGain={followGain}
                  currentFollowGain={followerGain}
                  monthsRemaining={calculateMonthsUntilEndOfYear()}
                  width={500}
                />
              </div>
              <div className="legends">
                <div className="legend-item">
                  <span className="legend-color current-growth"></span>
                  <span>Current growth</span>
                </div>
                <div className="legend-item">
                  <span className="legend-color goal-growth"></span>
                  <span>Goal growth</span>
                </div>
              </div>

              <div className="growth__rate">
                <div className="rate-container">
                  <p>{RTP}</p>
                  <div className="rate__description">
                    <h4>Reach to profile rate (RTP)</h4>
                    <small>
                      Your RTP is realitively low. Try to match your reel
                      content with your target audience to increase the
                      conversion rate.
                    </small>
                  </div>
                </div>
                <div className="rate-container">
                  <p>{PTF}</p>
                  <div className="rate__description">
                    <h4>Profile to follow rate (PTF)</h4>
                    <small>
                      Your PTF looks good. Think of this as the bridge between
                      your reach and the relevance of your profile's content.
                      The more they match, the higher your PTF will be.
                    </small>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalCalculator;
