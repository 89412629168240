import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./routes/router.js";
import { CookieProvider } from "./modules/CookieContext.js";
import { ApolloClientProvider } from "./modules/apolloClient.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookieProvider>
      <ApolloClientProvider>
        <Router />
      </ApolloClientProvider>
    </CookieProvider>
  </React.StrictMode>
);
