import React, { useState, useEffect } from "react";
import { FaInstagram, FaRegHeart, FaRegComment } from "react-icons/fa";
import { formatNumber } from "./FormatNumber";
import moment from "moment-timezone";

const PerPostEngagement = ({ competitors }) => {
  const [selectedPeriod, setSelectedPeriod] = useState("180");
  const [sortOrder, setSortOrder] = useState("none");
  const [processedData, setProcessedData] = useState([]);

  // Function to calculate engagement for a given media type
  const calculateEngagement = (mediaData, period) => {
    let engagement = { likes: 0, comments: 0, posts: 0 };
    const startDate = moment().subtract(period, "days"); // Calculate start date based on selected period

    // Filter media data within the selected period
    const filteredMedia = mediaData.filter((media) =>
      moment(media.timestamp).isAfter(startDate)
    );

    // Calculate engagement for the filtered media
    filteredMedia.forEach((media) => {
      engagement.likes += media.likes || 0;
      engagement.comments += media.comments || 0;
    });

    engagement.posts = filteredMedia.length;

    return engagement;
  };

  // Function to calculate total engagement for a competitor
  const calculateTotalEngagement = (competitor, period) => {
    const reelsEng = calculateEngagement(competitor.reels, period);
    const photosEng = calculateEngagement(competitor.photos, period);
    const carouselsEng = calculateEngagement(competitor.carousels, period);

    return (
      reelsEng.likes +
      reelsEng.comments +
      photosEng.likes +
      photosEng.comments +
      carouselsEng.likes +
      carouselsEng.comments
    );
  };

  useEffect(() => {
    // Calculate processed data for each competitor
    const processedCompetitors = competitors.map((competitor) => {
      const totalEngagement = calculateTotalEngagement(
        competitor,
        selectedPeriod
      );

      return {
        ...competitor,
        totalEngagement,
      };
    });

    // Sort the competitors based on total engagement (descending order)
    const sortedCompetitors = [...processedCompetitors].sort(
      (a, b) => b.totalEngagement - a.totalEngagement
    );

    setProcessedData(sortedCompetitors);
  }, [competitors, selectedPeriod]);

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <div className="competitor-comparison">
      <h3>Engagement per media type</h3>
      <div className="table__header">
        <div className="color__media">
          <p>Image</p>
          <p>Carousels</p>
          <p>Reels</p>
        </div>
        <div className="date__range">
          <select value={selectedPeriod} onChange={handlePeriodChange}>
            <option value="30">Last 30 days</option>
            <option value="60">Last 60 days</option>
            <option value="90">Last 3 months</option>
            <option value="180">Last half year</option>
          </select>
        </div>
      </div>

      <div className="followers">
        <table>
          <thead>
            <tr>
              <th>
                <FaInstagram size={15} />
              </th>
              <th>
                <FaRegHeart className="engagement__th" size={15} />
                <FaRegComment className="engagement__th" size={15} />
              </th>
              <th className="table__desc">Engagement per media type</th>
            </tr>
          </thead>
          <tbody>
            {processedData.map((competitor, key) => {
              const username = competitor.username || "n/a";

              // Calculate width percentages for the composite bar
              const maxEngagement =
                processedData.length > 0 ? processedData[0].totalEngagement : 0;

              const reelsWidth =
                maxEngagement > 0
                  ? (competitor.totalEngagement / maxEngagement) * 100
                  : 0;

              const photosWidth =
                maxEngagement > 0
                  ? (competitor.totalEngagement / maxEngagement) * 100
                  : 0;

              const carouselsWidth =
                maxEngagement > 0
                  ? (competitor.totalEngagement / maxEngagement) * 100
                  : 0;

              const leftRadius =
                photosWidth > 0
                  ? "10px"
                  : carouselsWidth > 0
                  ? "10px"
                  : reelsWidth > 0
                  ? "10px"
                  : "0";
              // Right border-radius
              const rightRadius =
                reelsWidth > 0
                  ? "10px"
                  : carouselsWidth > 0
                  ? "10px"
                  : photosWidth > 0
                  ? "10px"
                  : "0";

              return (
                <tr key={key}>
                  <td className="username__td">{username}</td>
                  <td className="followers__td">
                    {formatNumber(competitor.totalEngagement)}
                  </td>
                  <td className="media-bar-holder">
                    <div
                      className="media-bar"
                      style={{
                        width: `${
                          (competitor.totalEngagement / maxEngagement) * 100
                        }%`,
                      }}
                    >
                      {photosWidth > 0 && (
                        <div
                          className="bar-type photos"
                          style={{
                            width: `${photosWidth}%`,
                            borderTopLeftRadius: leftRadius,
                            borderBottomLeftRadius: leftRadius,
                          }}
                        ></div>
                      )}
                      {carouselsWidth > 0 && (
                        <div
                          className="bar-type carousels"
                          style={{
                            width: `${carouselsWidth}%`,
                            borderTopLeftRadius:
                              photosWidth === 0 ? leftRadius : "0",
                            borderBottomLeftRadius:
                              photosWidth === 0 ? leftRadius : "0",
                            borderTopRightRadius:
                              reelsWidth === 0 ? rightRadius : "0",
                            borderBottomRightRadius:
                              reelsWidth === 0 ? rightRadius : "0",
                          }}
                        ></div>
                      )}
                      {reelsWidth > 0 && (
                        <div
                          className="bar-type reels"
                          style={{
                            width: `${reelsWidth}%`,
                            borderTopRightRadius: rightRadius,
                            borderBottomRightRadius: rightRadius,
                          }}
                        ></div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div className="ai__container">
          <div className="ai__header">
            <p>
              <strong>AI engagement insight</strong>{" "}
              <span role="img" aria-label="lightbulb">
                💡
              </span>
            </p>
          </div>
          <div className="ai__content">
            <p>Coming soon</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PerPostEngagement;
