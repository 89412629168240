import React, { useEffect, useState } from "react";
import "../style/Funnel.css";
import FunnelGraph from "funnel-graph-js";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_USER_ACCOUNTS, GET_GROUPED_METRICS } from "../modules/queries.js";
import { gql } from "@apollo/client";

export default function Funnels() {
  const [reachData, setReachData] = useState([]);
  const [websiteTrafficData, setWebsiteTrafficData] = useState([]);
  const [salesData, setSalesData] = useState([]);

  const conditions = [
    { platform: "instagram", accountId: "17841400009052327", metric: "reach" },
    {
      platform: "tiktok",
      accountId: "-000okrOHusRnq5rWAalSoc1wkNjJPmln4iL",
      metric: "video_views",
    },
    {
      platform: "ga4",
      accountId: "283284385",
      metric: "visitors",
    },
    {
      platform: "ga4",
      accountId: "283284385",
      metric: "sales",
    },
  ];

  // Define the GET_GROUPED_METRICS query directly within the component
  const GET_GROUPED_METRICS = gql`
    query GetGroupedMetrics($conditions: [MetricCondition]!) {
      groupedMetrics(conditions: $conditions) {
        accountId
        platform
        metric
        username
        data {
          nonBreakdown {
            date
            value
          }
          breakdowns {
            key
            values {
              date
              value
            }
          }
        }
      }
    }
  `;

  const {
    data: groupedMetricsData,
    loading: groupedMetricsLoading,
    error: groupedMetricsError,
  } = useQuery(GET_GROUPED_METRICS, {
    variables: { conditions },
  });
  const metricsData = groupedMetricsData?.groupedMetrics;

  useEffect(() => {
    if (metricsData) {
      const startDate = new Date("2024-03-01").getTime();
      const endDate = new Date("2024-03-31").getTime();

      let reachAggregate = 0;
      let trafficAggregate = 0;
      let salesAggregate = 0;

      metricsData.forEach((metric) => {
        if (metric.metric === "reach" || metric.metric === "video_views") {
          // Aggregate for reach and video_views
          if (metric.data.breakdowns) {
            metric.data.breakdowns.forEach(({ key, values }) => {
              if (!["AD", "DEFAULT_DO_NOT_USE"].includes(key)) {
                values.forEach(({ date, value }) => {
                  const dateMillis = new Date(date).getTime();
                  if (dateMillis >= startDate && dateMillis <= endDate) {
                    reachAggregate += value;
                  }
                });
              }
            });
          }
        } else if (metric.metric === "visitors") {
          // Aggregate for visitors
          metric.data.nonBreakdown.forEach(({ date, value }) => {
            const dateMillis = new Date(date).getTime();
            if (dateMillis >= startDate && dateMillis <= endDate) {
              trafficAggregate += value;
            }
          });
        } else if (metric.metric === "sales") {
          // Aggregate for sales
          metric.data.nonBreakdown.forEach(({ date, value }) => {
            const dateMillis = new Date(date).getTime();
            if (dateMillis >= startDate && dateMillis <= endDate) {
              salesAggregate += value;
            }
          });
          salesAggregate = salesAggregate / 100;
        }
      });

      // Update states
      setReachData([reachAggregate]);
      setWebsiteTrafficData([trafficAggregate]);
      setSalesData([salesAggregate]);
    }
  }, [metricsData]);

  useEffect(() => {
    if (
      reachData.length > 0 &&
      websiteTrafficData.length > 0 &&
      salesData.length > 0
    ) {
      const data = {
        labels: ["People reached", "Website Traffic", "Sales"],
        subLabels: ["Organic Instagram", "Organic TikTok", "Organic LinkedIn"],
        colors: [
          ["#a5d6a7", "#81c784", "#66bb6a"], // Fresh green gradient
          ["#a5d6a7", "#4caf50", "#388e3c"], // Vibrant nature gradient
          ["#a5d6a7", "#1b5e20", "#004d40"], // Deep forest gradient
        ],
        values: [
          [20000, 15000, 10000],
          [5000, 8000, 1000],
          [1000, 2000, 500],
        ],
      };

      const options = {
        container: ".funnel-container",
        gradientDirection: "horizontal",
        data: data,
        displayPercent: true,
        direction: "horizontal",
        width: 1000,
        height: 350,
      };

      try {
        const graph = new FunnelGraph(options);
        graph.draw();
      } catch (e) {
        console.error("There was an issue initializing the funnel graph:", e);
      }
    }
  }, [reachData, websiteTrafficData, salesData]);

  return (
    <div className="dashboard">
      <p>🌱 Organic Funnel</p>
      <div
        className="funnel-container"
        style={{ width: "100%", height: "400px" }}
      />
    </div>
  );
}
