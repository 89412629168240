import React from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLabel,
  VictoryScatter,
  VictoryArea,
} from "victory";
import moment from "moment";
import { FaImage, FaImages, FaFilm } from "react-icons/fa";
import { formatNumber } from "./Converter";
import { useLazyQuery } from "@apollo/client";
import { GET_MEDIA_METRICS } from "./queries.js";

const CustomTooltip = (props) => {
  // Destructure props for clarity
  const { onMediaClick } = props;
  const mediaId = props.data[0]?.mediaId || null;
  const accountId = props.data[0]?.accountId || null;
  const { x, y, datum, active } = props;
  // Only display the image if the tooltip is active
  if (!active) return null;
  // Define the size of the image and the offset
  const imageHeight = 50;
  const imageWidth = 30;
  const imageOffset = 10;

  return (
    <g
      className="g"
      onClick={(e) => {
        e.preventDefault();
        onMediaClick(accountId, mediaId);
      }}
    >
      <image
        href={datum.mediaUrl}
        x={x - imageWidth / 2}
        y={y - imageHeight - imageOffset}
        width={imageWidth}
        height={imageHeight}
        preserveAspectRatio="xMidYMid slice"
      />
      {/* Optionally, add text below the image */}
      <text
        x={x}
        y={y + imageOffset} // Position below the image
        textAnchor="middle"
        fontSize={10}
        fill="#455A64"
      >
        {datum.label}
      </text>
    </g>
  );
};

function calculateTotalsAndGrowth(data, start, end) {
  const currentPeriodStart = moment(start);
  const currentPeriodEnd = moment(end);

  // Previous period calculation (for growth)
  const previousPeriodEnd = moment(start).subtract(1, "days");
  const previousPeriodStart = previousPeriodEnd
    .clone()
    .subtract(currentPeriodEnd.diff(currentPeriodStart), "milliseconds");

  // Last year's period calculation
  const lastYearStart = currentPeriodStart.clone().subtract(1, "year");
  const lastYearEnd = currentPeriodEnd.clone().subtract(1, "year");

  const calculateTotalForPeriod = (data, periodStart, periodEnd) => {
    return data.reduce((total, point) => {
      const pointDate = moment(point.date);
      if (
        pointDate.isSameOrAfter(periodStart) &&
        pointDate.isSameOrBefore(periodEnd)
      ) {
        return total + point.value;
      }
      return total;
    }, 0);
  };

  return data.map((metric) => {
    const currentTotal = calculateTotalForPeriod(
      metric.data,
      currentPeriodStart,
      currentPeriodEnd
    );
    const previousTotal = calculateTotalForPeriod(
      metric.data,
      previousPeriodStart,
      previousPeriodEnd
    );
    const totalLY = calculateTotalForPeriod(
      metric.data,
      lastYearStart,
      lastYearEnd
    );
    const growth = ((currentTotal - previousTotal) / previousTotal) * 100 || 0;
    const growthLY =
      totalLY !== 0 ? ((currentTotal - totalLY) / totalLY) * 100 || 0 : "N/A";

    return {
      ...metric,
      currentTotal,
      previousTotal,
      growth: growth.toFixed(2) + "%",
      totalLY: totalLY !== 0 ? totalLY : "N/A",
      growthLY:
        typeof growthLY === "number" ? growthLY.toFixed(2) + "%" : growthLY,
    };
  });
}

const CustomChart = ({ data, startDate, endDate, log, mediaItem }) => {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const [popupData, setPopupData] = React.useState(null);
  const [getMediaMetrics, { loading, data: mediaMetricsData, error }] =
    useLazyQuery(GET_MEDIA_METRICS);

  const handleFetchMediaMetrics = (accountId, mediaId) => {
    getMediaMetrics({
      variables: { accountId, mediaId },
      onCompleted: (data) => {
        setPopupData(data);
        setIsPopupVisible(true);
      },
    });
  };

  const start = moment(startDate);
  const end = moment(endDate);

  const diffDays = end.diff(start, "days");
  const diffMonths = end.diff(start, "months");

  let tickFormat;
  let tickValues = [];

  if (diffDays <= 28) {
    // Up to 28 days: show days
    tickFormat = (x) => moment(x).format("MMM D");
    let current = moment(startDate).isoWeekday(8); // This finds the next Monday after the start date
    if (current.isAfter(startDate, "day")) {
      // If the start date is after the first Monday, then go back one week to include the first Monday of the range
      current.subtract(7, "days");
    }
    while (current.isBefore(endDate) || current.isSame(endDate, "day")) {
      if (current.isoWeekday() === 1) {
        // Check if the day is Monday
        tickValues.push(current.format("YYYY-MM-DD"));
      }
      current.add(1, "days");
    }
  } else if (diffMonths > 2 && diffMonths <= 12) {
    // Between 2 to 3 months: show weeks

    tickFormat = (x) => moment(x).format("MMM");
    let current = moment(startDate).startOf("month");
    while (current.isSameOrBefore(endDate, "month")) {
      tickValues.push(current.format("YYYY-MM-DD"));
      current.add(1, "month");
    }
  } else if (diffMonths > 12) {
    // More than 12 months: show month and year
    tickFormat = (x) => moment(x).format("MMM YYYY");
    let current = moment(startDate).startOf("month");
    while (current.isBefore(endDate)) {
      tickValues.push(current.format("YYYY-MM-DD"));
      current.add(3, "months"); // You might want to adjust this to 'months' if you prefer monthly ticks for ultra-long term
    }
  } else {
    // All other cases: show days
    tickFormat = (x) => moment(x).format("MMM D");
    let current = moment(startDate);
    while (current.isBefore(endDate) || current.isSame(endDate, "day")) {
      tickValues.push(current.format("YYYY-MM-DD"));
      current.add(1, "days");
    }
  }

  const filteredMetricsData = data.map((metric) => {
    const processedData = metric.data
      .filter((point) => {
        const pointDate = moment(point.date);
        return pointDate.isSameOrAfter(start) && pointDate.isSameOrBefore(end);
      })
      .map((point) => ({ x: new Date(point.date), y: point.value })); // Convert to { x, y } format

    const processedAds = metric.ads
      ? metric.ads
          .filter((ad) => {
            const adDate = moment(ad.date);
            return adDate.isSameOrAfter(start) && adDate.isSameOrBefore(end);
          })
          .map((ad) => ({ x: new Date(ad.date), y: ad.value }))
      : null;

    return {
      color: metric.color,
      name: metric.name,
      metric: metric.metric,
      ads: processedAds,
      showAds: metric.showAds,
      data: processedData,
    };
  });

  const allYValues = filteredMetricsData.flatMap((metric) => {
    // Start with all y values from the metric's main data
    let yValues = metric.data.map((point) => point.y);

    // If showAds is true, include the y values from the ads data as well
    if (metric.showAds && metric.ads) {
      const adsYValues = metric.ads.map((ad) => ad.y);
      yValues = yValues.concat(adsYValues);
    }

    return yValues;
  });

  // Find the x-axis domain for each set of metric data
  const allXValues = filteredMetricsData.flatMap((metric) =>
    metric.data.map((point) => point.x)
  );

  // Use a default min and max if there are no values
  const defaultYMin = 0;
  const defaultYMax = 1;

  const yMin = allYValues.length > 0 ? Math.min(...allYValues) : defaultYMin;
  const yMax = allYValues.length > 0 ? Math.max(...allYValues) : defaultYMax;

  // For the x-axis, we use the earliest and latest date from the filtered data
  const xMin =
    allXValues.length > 0
      ? new Date(Math.min(...allXValues))
      : new Date(startDate);
  const xMax =
    allXValues.length > 0
      ? new Date(Math.max(...allXValues))
      : new Date(endDate);

  const victoryTooltip = (
    <VictoryTooltip
      flyoutStyle={{
        stroke: "#e3e3e3",
        fill: "white",
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
      }}
      style={{ fontSize: 10 }}
    />
  );

  const useLog = log ? "log" : "linear";
  const totalsAndGrowth = calculateTotalsAndGrowth(data, startDate, endDate);

  // const mediaDataForChart = mediaItem?.map((media) => {
  //   const fletteredMedia = media.media.map((point) => {
  //     return {
  //       x: new Date(parseInt(point.timestamp)),
  //       y: 0,
  //       mediaItem: media,
  //     };
  //   });
  //   return fletteredMedia;
  // });

  // Flatten the array of media items
  // Assuming `mediaItem` is an array of media items with a nested `media` array in each item
  // and `data` is an array of metrics data with `showMedia` and `instagramAccountId` properties
  // `start` and `end` are the start and end timestamps for the filtering range

  // Get an array of accountIds to show based on `showMedia`
  const accountIdsToShow = data
    .filter((metric) => metric.showMedia)
    .map((metric) => metric.accountId);

  const mediaDataForChart = Object.values(mediaItem)
    .flatMap((item) => {
      // item is already an object with media properties, including accountId
      const mediaUrl =
        item.media_type === "reels" || item.media_type === "video"
          ? item.thumbnail_url
          : item.media_url;
      return {
        x: new Date(item.published_date),
        y: yMin,
        mediaType: item.media_type,
        mediaId: item.media_id,
        permalink: item.permalink,
        mediaUrl: mediaUrl,
        color: item.color,
        accountId: item.accountId,
      };
    })
    .filter((media) => accountIdsToShow.includes(media.accountId));

  // Filter the flatMediaData to only include those mediaItems whose instagramAccountId is in the accountIdsToShow
  // and whose timestamp is within the start and end range
  // Flatten the array of media items
  // Flatten the array of media items

  // const mediaDataForChart = flatMediaData.filter((media) => {
  //   // Check if the accountId matches
  //   const isAccountIdIncluded = accountIdsToShow.includes(
  //     media.mediaItem.instagramAccountId
  //   );

  //   // Check if the timestamp is within the start and end range
  //   const mediaDate = moment(media.x); // 'media.x' is already a Date object from the map above
  //   const isWithinDateRange =
  //     mediaDate.isSameOrAfter(moment(start)) &&
  //     mediaDate.isSameOrBefore(moment(end));

  //   // Include media if both conditions are true
  //   return isAccountIdIncluded && isWithinDateRange;
  // });

  const currentPeriodStart = moment(startDate);
  const currentPeriodEnd = moment(endDate);

  const previousPeriodEnd = currentPeriodStart.clone().subtract(1, "days");
  const previousPeriodStart = previousPeriodEnd
    .clone()
    .subtract(currentPeriodEnd.diff(currentPeriodStart), "milliseconds");

  const lastYearStart = currentPeriodStart.clone().subtract(1, "year");
  const lastYearEnd = currentPeriodEnd.clone().subtract(1, "year");

  let mediaCountsByAccount = {};

  if (mediaItem?.length > 0) {
    mediaItem.forEach((media) => {
      const accountId = media.accountId;
      if (!mediaCountsByAccount[accountId]) {
        // Initialize the counts for each account
        mediaCountsByAccount[accountId] = {
          current: { reels: 0, carousel: 0, image: 0 },
          previous: { reels: 0, carousel: 0, image: 0 },
          lastYear: { reels: 0, carousel: 0, image: 0 },
          color: media.color, // Assume the same color for all media items from the same account
        };
      }

      // Convert the media's published_date with moment
      const itemDate = moment(media.published_date);

      // Increment the count for the current period
      if (
        itemDate.isSameOrAfter(currentPeriodStart) &&
        itemDate.isSameOrBefore(currentPeriodEnd)
      ) {
        mediaCountsByAccount[accountId].current[media.media_type]++;
      }
      // Increment the count for the previous period
      if (
        itemDate.isSameOrAfter(previousPeriodStart) &&
        itemDate.isSameOrBefore(previousPeriodEnd)
      ) {
        mediaCountsByAccount[accountId].previous[media.media_type]++;
      }
      // Increment the count for the last year period
      if (
        itemDate.isSameOrAfter(lastYearStart) &&
        itemDate.isSameOrBefore(lastYearEnd)
      ) {
        mediaCountsByAccount[accountId].lastYear[media.media_type]++;
      }
    });
  }

  // Convert the object to an array for easier use in the JSX
  let mediaCounts = Object.keys(mediaCountsByAccount).map((accountId) => ({
    accountId,
    ...mediaCountsByAccount[accountId],
  }));

  return (
    <>
      <VictoryChart
        key={filteredMetricsData.map((metric) => metric.accountId).join("-")}
        theme={VictoryTheme.material}
        scale={{ x: "time", y: useLog }}
        containerComponent={
          <VictoryVoronoiContainer labelComponent={victoryTooltip} />
        }
        domain={{ x: [xMin, xMax], y: [yMin, yMax] }} // Set the domain for both axes
        width={500} // Adjust the width as needed
        height={300} // Adjust the height as needed
        style={{
          parent: { background: "#fff" }, // Set the background to white
        }}
      >
        <VictoryAxis
          fixLabelOverlap={true}
          tickFormat={tickFormat}
          style={{
            tickLabels: { fill: "black", fontSize: 8 }, // Tick label color and font size
          }}
        />
        <VictoryAxis
          dependentAxis
          fixLabelOverlap={true}
          style={{
            tickLabels: { fill: "black", fontSize: 8 }, // Tick label color and font size
          }}
        />
        <VictoryAxis
          dependentAxis
          fixLabelOverlap={true}
          orientation="right"
          style={{
            tickLabels: { fill: "black", fontSize: 8 }, // Tick label color and font size
          }}
        />

        {filteredMetricsData.map((metric, index) => (
          <defs key={`gradientDef-${index}`}>
            <linearGradient
              id={`gradient-${index}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor={metric.color} stopOpacity={0.8} />
              <stop offset="100%" stopColor={metric.color} stopOpacity={0.2} />
            </linearGradient>
          </defs>
        ))}

        {filteredMetricsData.length > 0 ? (
          filteredMetricsData.flatMap((metric, index) => {
            // The main data VictoryArea
            const metricDataArea = (
              <VictoryArea
                key={`area-metric-${index}`}
                data={metric.data.map((d) => ({ ...d, y0: yMin }))}
                style={{
                  data: {
                    fill: `url(#gradient-${index})`,
                    stroke: metric.color,
                    strokeWidth: 1.4,
                  },
                }}
              />
            );

            // The ads data VictoryArea, only if showAds is true
            const adsDataArea =
              metric.showAds && metric.ads ? (
                <VictoryArea
                  key={`area-ads-${index}`}
                  data={metric.ads.map((d) => ({ ...d, y0: yMin }))}
                  style={{
                    data: {
                      fill: metric.color, // Consider using a different color or pattern
                      stroke: metric.color,
                      strokeWidth: 1.4,
                      opacity: 0.6, // Optional: make the ads area slightly transparent
                    },
                  }}
                />
              ) : null;

            // Return an array that includes the metric area and possibly the ads area
            return [metricDataArea, adsDataArea].filter(Boolean); // The filter(Boolean) will remove any null values
          })
        ) : (
          <VictoryLabel
            text="No data selected in the set"
            x={250}
            y={150}
            textAnchor="middle"
          />
        )}

        {mediaDataForChart?.length > 0 ? (
          mediaDataForChart?.map((media, index) => {
            // Create an array with a single data point for the VictoryScatter component
            const label = media.mediaType
              ? media.mediaType.toString()
              : "Unknown Type";

            // how should I pass the label in the labels prop?

            return (
              <VictoryScatter
                key={`scatter-${index}`}
                data={[
                  {
                    x: media.x,
                    y: media.y,
                    label: label,
                    mediaUrl: media.mediaUrl,
                    permalink: media.permalink,
                    mediaId: media.mediaId,
                    accountId: media.accountId,
                  },
                ]} // Include the mediaUrl in the data point
                size={2}
                style={{ data: { fill: media.color } }}
                renderInPortal={false}
                labelComponent={
                  <CustomTooltip
                  // onMediaClick={handleFetchMediaMetrics}
                  />
                } // Pass the CustomTooltip component
              />
            );
          })
        ) : (
          <></>
        )}
      </VictoryChart>
      <div className="scorecard__wrapper">
        {totalsAndGrowth.length > 0 && mediaCounts.length > 0 ? (
          totalsAndGrowth.map((metric, index) => {
            const mediaCount = mediaCounts[index];

            return (
              <div key={index} className="scorecard__div">
                <div className="scorecard__header">
                  <div
                    style={{
                      backgroundColor: metric.color,
                      width: "14px",
                      height: "14px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <h4>
                    {metric.name} ({metric.metric})
                  </h4>
                </div>

                <div className="scorecard__box" key={`box-${index}`}>
                  <div className="box__content">
                    <h2>Current</h2>
                    <h4>{formatNumber(metric.currentTotal)}</h4>
                  </div>

                  <div className="box__content">
                    <h2>Previous</h2>
                    <h4>{formatNumber(metric.previousTotal)}</h4>
                    <div
                      className="box__growth"
                      style={{
                        color: `${metric.growth >= "0%" ? "#03ff03" : "red"}`,
                      }}
                    >
                      <p>{metric.growth} </p>
                    </div>
                  </div>
                  <div className="box__content">
                    <h2>Last year</h2>
                    <h4>{formatNumber(metric.totalLY)}</h4>
                    <div
                      className="box__growth"
                      style={{
                        color: `${metric.growthLY >= "0%" ? "#03ff03" : "red"}`,
                      }}
                    >
                      <p>{metric.growthLY}</p>
                    </div>
                  </div>
                  <div className="box__content">
                    <div className="box__content__icons">
                      <FaFilm />
                      <p>{mediaCount?.current.reels}</p>
                    </div>
                    <div className="box__content__icons">
                      <FaImages />
                      <p>{mediaCount?.current.carousel}</p>
                    </div>
                    <div className="box__content__icons">
                      <FaImage />
                      <p>{mediaCount?.current.image}</p>
                    </div>
                  </div>
                  <div className="box__content">
                    <div className="box__content__icons">
                      <FaFilm />
                      <p>{mediaCount?.previous.reels}</p>
                    </div>
                    <div className="box__content__icons">
                      <FaImages />
                      <p>{mediaCount?.previous.carousel}</p>
                    </div>
                    <div className="box__content__icons">
                      <FaImage />
                      <p>{mediaCount?.previous.image}</p>
                    </div>
                  </div>
                  <div className="box__content">
                    <div className="box__content__icons">
                      <FaFilm />
                      <p> {mediaCount?.lastYear.reels}</p>
                    </div>
                    <div className="box__content__icons">
                      <FaImages />
                      <p>{mediaCount?.lastYear.carousel}</p>
                    </div>
                    <div className="box__content__icons">
                      <FaImage />
                      <p>{mediaCount?.lastYear.image}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
      {isPopupVisible && (
        <div className="chart-popup">
          {loading && <div>Loading...</div>}
          {error && <div>Error loading data</div>}
          {popupData && (
            <div className="popup-container">
              <button
                onClick={() => setIsPopupVisible(false)}
                className="close-button"
              >
                Close
              </button>
              <div>
                <div>
                  <img
                    alt="Media Thumbnail"
                    src={
                      popupData.igMediaMetrics.media.thumbnail_url
                        ? popupData.igMediaMetrics.media.thumbnail_url
                        : popupData.igMediaMetrics.media.media_url
                    }
                    onClick={() =>
                      window.open(
                        popupData.igMediaMetrics.media.permalink,
                        "_blank"
                      )
                    }
                  />
                  <p>
                    {
                      new Date(
                        parseInt(popupData.igMediaMetrics.media.timestamp)
                      )
                        .toISOString()
                        .split("T")[0]
                    }
                  </p>
                  <p>{popupData.igMediaMetrics.media.caption}</p>
                </div>
                <div>
                  {Object.entries(popupData.igMediaMetrics.media).map(
                    ([key, value]) => {
                      // Optionally, you can filter out keys you don't want to display
                      if (
                        [
                          "id",
                          "media_type",
                          "caption",
                          "__typename",
                          "timestamp",
                          "media_url",
                          "thumbnail_url",
                          "permalink",
                        ].includes(key) ||
                        value === 0 ||
                        value === undefined ||
                        value === "" ||
                        value === null
                      ) {
                        return null; // Skip these keys
                      }

                      return (
                        <p key={key}>
                          {key.replace(/_/g, " ").charAt(0).toUpperCase() +
                            key.replace(/_/g, " ").slice(1)}
                          : {formatNumber(value)}
                        </p>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomChart;
