import { SuperUserMenu, ClientAdminMenu } from "./Menus";
import React from "react";
import "../style/NavBar.css";

const NavBar = ({ role }) => {
  const renderMenu = () => {
    switch (role) {
      case "superUser":
        return <SuperUserMenu />;
      case "clientAdmin":
        return <ClientAdminMenu />;
      case "test":
        return <ClientAdminMenu />;
      default:
        return null;
    }
  };
  return <>{renderMenu()}</>;
};

export default NavBar;
