const ReachGrowthBar = ({ currentReach, goalReach }) => {
  console.log("currentReach:", currentReach);
  console.log("goalReach:", goalReach);

  // Calculate the difference between goal and current reach
  const reachDifference = goalReach - currentReach;
  // Determine the base for percentage calculation
  const baseValue = Math.abs(reachDifference) + currentReach;
  // Calculate percentages for layout
  const currentReachHeightPercent = ((currentReach / baseValue) * 100).toFixed(
    2
  );
  const reachDifferenceHeightPercent = (
    (Math.abs(reachDifference) / baseValue) *
    100
  ).toFixed(2);
  // Determine the color based on whether the goal is an increase or decrease
  const reachDifferenceColor = reachDifference >= 0 ? "#fbde51" : "#ff6b6b"; // Using red to indicate a decrease

  // Format the difference value for display, showing "-" if goal is less than current reach
  const formattedDifference =
    reachDifference >= 0
      ? `+${reachDifference.toLocaleString()}`
      : reachDifference.toLocaleString();

  return (
    <div
      className="reach-growth-bar-container"
      style={{ height: "100%", position: "relative" }}
    >
      {reachDifference !== 0 && (
        <div
          className="reach-difference"
          style={{
            height: `${reachDifferenceHeightPercent}%`,
            backgroundColor: reachDifferenceColor,
            position: "absolute",
            bottom: `${currentReachHeightPercent}%`,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>{formattedDifference}</span>
        </div>
      )}
      <div
        className="current-reach"
        style={{
          height: `${currentReachHeightPercent}%`,
          backgroundColor: "#bcb1f8",
          position: "absolute",
          bottom: 0,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>{currentReach.toLocaleString()}</span>
      </div>
    </div>
  );
};

export default ReachGrowthBar;
