import React, { useState } from "react";
import api from "../modules/Api.js";
import "../style/App.css";

export default function Integrations() {
  const [shopifyStoreName, setShopifyStoreName] = useState("");

  const getStripeUrl = async () => {
    try {
      const response = await api.get("oauth/stripe/url");
      if (response.status !== 200) throw new Error("Failed to get Stripe URL");

      window.open(response.data, "_self");
    } catch (error) {
      console.error(error);
    }
  };

  const getTikTokUrl = async () => {
    try {
      const response = await api.get("oauth/tiktok/url");
      if (response.status !== 200) throw new Error("Failed to get TikTok URL");

      window.open(response.data, "_self");
    } catch (error) {
      console.error(error);
    }
  };

  const getMetaUrl = async () => {
    try {
      const response = await api.get("oauth/meta/url");
      if (response.status !== 200) throw new Error("Failed to get Meta URL");

      window.open(response.data, "_self");
    } catch (error) {
      console.error(error);
    }
  };

  const getGa4Url = async () => {
    try {
      const response = await api.get("oauth/ga4/url");
      if (response.status !== 200) throw new Error("Failed to get GA4 URL");

      window.open(response.data, "_self");
    } catch (error) {
      console.error(error);
    }
  };

  const getSearchConsoleUrl = async () => {
    try {
      const response = await api.get("oauth/search/console/url");
      if (response.status !== 200)
        throw new Error("Failed to get Search Console URL");

      window.open(response.data, "_self");
    } catch (error) {
      console.error(error);
    }
  };

  const getShopifyUrl = async () => {
    try {
      const response = await api.post("oauth/shopify/url", {
        shop: shopifyStoreName,
      });
      if (response.status !== 200) throw new Error("Failed to get Shopify URL");

      window.open(response.data, "_self");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="dashboard">
      <div className="integrations">
        <div className="integration-box">
          <div className="integration-content">
            <img src="./stripe_logo.png" alt="Stripe logo" />
            <p>Stripe</p>
          </div>
          <small>Get your Stripe data flowing in to the app.</small>
          <button disabled onClick={getStripeUrl}>
            Connect
          </button>
        </div>
        <div className="integration-box">
          <div className="integration-content">
            <img src="./TikTok-logo-RGB-Stacked-Black.png" alt="TikTok logo" />
            <p>TikTok</p>
          </div>
          <small>Get full insights on your tiktok account(s).</small>
          <button onClick={getTikTokUrl}>Connect</button>
        </div>
        <div className="integration-box">
          <div className="integration-content">
            <img src="./Meta_lockup_positive primary_RGB.png" alt="Meta logo" />
            <p>Meta</p>
          </div>
          <small>Get full insights on your meta account(s).</small>
          <button disabled onClick={getMetaUrl}>
            Connect
          </button>
        </div>
        <div className="integration-box">
          <div className="integration-content">
            <img src="./GA4_logo.png" alt="GA4 logo" />
            <p>GA4</p>
          </div>
          <small>Get your GA4 data flowing in to the app.</small>
          <button onClick={getGa4Url}>Connect</button>
        </div>
        <div className="integration-box">
          <div className="integration-content">
            <img src="./GA4_logo.png" alt="search console logo" />
            <p>Google Search Console</p>
          </div>
          <small>Get your search console data flowing in to the app.</small>
          <button onClick={getSearchConsoleUrl}>Connect</button>
        </div>
        <div className="integration-box">
          <div className="integration-content">
            <img src="./shopify_logo.png" alt="Shopify logo" />
            <p>Shopify</p>
          </div>
          <small>Get your Shopfy data flowing in to the app.</small>
          <div className="shopify-input">
            <input
              placeholder="your store name.."
              onChange={(e) => setShopifyStoreName(e.target.value)}
            />
            <p>.myshopify.com</p>
          </div>
          <button onClick={getShopifyUrl}>Connect</button>
        </div>
      </div>
    </div>
  );
}
