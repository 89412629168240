import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./Api.js";
import { useCookie } from "../modules/CookieContext.js";

const RequireAuth = ({ children, requiredRole }) => {
  const { role } = useCookie();

  const hasRequiredRole = requiredRole
    ? requiredRole
        .split(",")
        .map((role) => role.trim())
        .includes(role)
    : true;

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await api.post("/route/validate");
        if (response.data.isValid) {
          setIsAuthenticated(true);
        } else {
          console.log("User is not authenticated");
          console.log(response.data);

          navigate("/?error=auth");
        }
      } catch (error) {
        console.error("Error during authentication:", error);
        navigate("/?error=auth");
      } finally {
        setIsLoading(false);
      }
    };

    verifyUser();
  }, [navigate]);

  useEffect(() => {
    // If the user is authenticated but does not have the required role, redirect to /dash
    if (
      !isLoading &&
      isAuthenticated &&
      requiredRole &&
      role !== requiredRole
    ) {
      navigate("/dash");
    }
  }, [isLoading, isAuthenticated, requiredRole, role, navigate]);

  if (isLoading) {
    return (
      <div aria-busy="true" aria-label="Loading">
        Loading...
      </div>
    );
  }

  return isAuthenticated ? children : null;
};

export default RequireAuth;
