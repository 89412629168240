import React, { useEffect, useState } from "react";
import "../style/Dashboard.css";
import { useCookie } from "../modules/CookieContext.js";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_USER_ACCOUNTS,
  GET_USER,
  UPLOAD_GOAL_MUTATION,
  DELETE_GOAL_MUTATION,
} from "../modules/queries.js";
import "../style/Dashboard.css";
import GoalChart from "../modules/GoalChart.js";

export default function Dashboard() {
  const { name } = useCookie();
  const [errorMessage, setErrorMessage] = useState("");
  const [flip, setFlip] = useState(false);
  const [platformGoal, setPlatformGoal] = useState("");
  const [accountIdGoal, setAccountIdGoal] = useState("");
  const [metricGoal, setMetricGoal] = useState("");
  const [availableGoals, setAvailableGoals] = useState([]);
  const [monthlyGoal, setMonthlyGoal] = useState(0);
  const [activeGoal, setActiveGoal] = useState(null);
  const [dataPath, setDataPath] = useState(null);

  const [uploadGoal] = useMutation(UPLOAD_GOAL_MUTATION);
  const [deleteGoal] = useMutation(DELETE_GOAL_MUTATION);

  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData,
  } = useQuery(GET_USER_ACCOUNTS);

  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery(GET_USER);

  useEffect(() => {
    if (userData) {
      const userGoals = userData.getUser.goals;

      // Flatten Instagram accounts to an array of { platform, accountId, metric } objects
      const accountMetrics = accountsData
        ? accountsData.getUserAccounts.flatMap((account) =>
            account.availableMetrics.map((metric) => ({
              platform: account.platform,
              accountId: account.accountId,
              metric: metric,
              name: account.username,
            }))
          )
        : [];

      // Filter out the metrics that exist in the user's goals
      const metricsAvailableForGoals = accountMetrics.filter(
        (accountMetric) =>
          !userGoals.some(
            (goal) =>
              goal.platform === accountMetric.platform &&
              goal.accountId === accountMetric.accountId &&
              goal.metric === accountMetric.metric
          )
      );

      setAvailableGoals(metricsAvailableForGoals);
    }
  }, [userData, accountsData]);

  const handlePlatformChange = (e) => {
    // When platform changes, reset dependent fields
    setPlatformGoal(e.target.value);
    setAccountIdGoal(""); // Reset account selection
    setMetricGoal(""); // Reset metric selection
    setMonthlyGoal(0); // Reset monthly goal
  };

  const handleAccountChange = (e) => {
    // When account changes, reset dependent fields
    setAccountIdGoal(e.target.value);
    setMetricGoal(""); // Reset metric selection
    setMonthlyGoal(0); // Reset monthly goal
  };

  const handleMetricChange = (e) => {
    setMetricGoal(e.target.value);
  };

  const handleMonthlyGoalChange = (e) => {
    setMonthlyGoal(+e.target.value);
  };

  const handleGoalCardClick = (goal) => {
    if (activeGoal && activeGoal._id === goal._id) {
      setActiveGoal(null); // Deactivate if the same goal is clicked again
    } else {
      setActiveGoal(goal); // Activate the clicked goal
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }, [errorMessage]);

  const handleDeleteGoal = (goalId) => {
    deleteGoal({
      variables: { goalId: goalId },
    })
      .then((response) => {
        // Handle the successful deletion
        console.log("Goal deleted:", response.data.deleteGoal);
        window.location.reload();

        // Optionally, refresh the data or update the state to remove the goal from the UI
      })
      .catch((error) => {
        // Handle the error
        console.error("Error deleting goal:", error);
      });
  };

  // whats the command to open the ai chat here in cursor?

  const setGoal = () => {
    // Check if any parameters are missing and set an error message accordingly
    if (!platformGoal) {
      setErrorMessage("Platform is missing");
      return;
    } else if (!accountIdGoal) {
      setErrorMessage("Account ID is missing");
      return;
    } else if (!metricGoal) {
      setErrorMessage("Metric is missing");
      return;
    } else if (!monthlyGoal) {
      setErrorMessage("Monthly goal is missing");
      return;
    }

    // If all parameters are provided, proceed to upload the goal
    uploadGoal({
      variables: {
        goalInput: {
          platform: platformGoal,
          accountId: accountIdGoal,
          metric: metricGoal,
          monthlyGoal: monthlyGoal,
        },
      },
    })
      .then((response) => {
        // Check the response if needed or just reload
        window.location.reload();
      })
      .catch((error) => {
        // Handle the error, e.g., set an error message
        setErrorMessage(error.message);
      });
  };

  console.log("Available goals", availableGoals);
  return (
    <div className="dashboard">
      {activeGoal && (
        <div
          className="goal-card-backdrop"
          onClick={() => setActiveGoal(null)}
        ></div>
      )}
      <div className="dashboard__header">
        <h2>Welcome {name.split(" ")[0]}!</h2>
      </div>
      <div className="goal-grid">
        {userData &&
          userData.getUser.goals.length > 0 &&
          userData.getUser.goals.map((goal, index) => {
            console.log("goal:", goal);

            return (
              <div className="goal-container">
                <select
                  onChange={(e) =>
                    e.target.value === "delete" && handleDeleteGoal(goal._id)
                  }
                >
                  <option value="setting">Settings</option>
                  <option value="delete">Delete</option>
                </select>

                <div
                  key={goal._id}
                  className={`goal-card ${
                    activeGoal && activeGoal._id === goal._id ? "active" : ""
                  }`}
                  onClick={() => handleGoalCardClick(goal)}
                >
                  {
                    <GoalChart
                      platform={goal.platform}
                      accountId={goal.accountId}
                      metric={goal.metric}
                      monthlyGoal={goal.monthlyGoal}
                    />
                  }
                </div>
              </div>
            );
          })}

        {!flip && availableGoals.length > 0 && (
          <div className="goal-container">
            <select disabled={true}>
              <option value="setting">Settings</option>
            </select>
            <div
              className="goal-card add-goal-card"
              onClick={() => setFlip(true)}
            >
              <div className="add-goal-content">
                <img src="./black-03.png" />
                <h2>Add organic goal</h2>
              </div>
            </div>
          </div>
        )}
        {flip && availableGoals.length > 0 && (
          <div className="goal-container">
            <div className="set-goal-header">
              <select
                defaultValue=""
                name="platform"
                onChange={handlePlatformChange}
              >
                <option value="" disabled hidden>
                  Platform..
                </option>
                {[...new Set(availableGoals.map((goal) => goal.platform))].map(
                  (platform) => (
                    <option key={platform} value={platform}>
                      {platform.charAt(0).toUpperCase() + platform.slice(1)}
                    </option>
                  )
                )}
              </select>
              <select
                name="instagramAccountId"
                onChange={handleAccountChange}
                disabled={!platformGoal} // Disables the select if platformGoal is falsy
                defaultValue="" // Ensures the user has to make a selection
              >
                <option value="" disabled>
                  Select Account
                </option>
                {platformGoal &&
                  [
                    ...new Map(
                      availableGoals
                        .filter((goal) => goal.platform === platformGoal) // Filter by selected platform
                        .map((goal) => [goal.accountId, goal]) // Use accountId as a key for deduplication
                    ).values(),
                  ] // Map values are unique by accountId
                    .map((goal) => (
                      <option key={goal.accountId} value={goal.accountId}>
                        {goal.name}
                      </option>
                    ))}
              </select>

              <select
                name="metric"
                value={metricGoal} // <-- Make sure to bind the select value to your component state
                onChange={handleMetricChange}
                disabled={!platformGoal || !accountIdGoal}
              >
                <option value="" disabled>
                  Select Metric
                </option>
                {availableGoals
                  .filter(
                    (goal) =>
                      goal.platform === platformGoal &&
                      goal.accountId === accountIdGoal
                  )
                  .map((goal) => (
                    <option key={goal.metric} value={goal.metric}>
                      {goal.metric.charAt(0).toUpperCase() +
                        goal.metric.slice(1)}
                    </option>
                  ))}
              </select>
              <input
                onChange={handleMonthlyGoalChange}
                type="text"
                placeholder="Monthly Goal"
                disabled={!platformGoal && !accountIdGoal && !metricGoal}
              />
              <button
                disabled={monthlyGoal < 0}
                className="set-goal-btn"
                onClick={setGoal}
              >
                SET GOAL
              </button>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div className="goal-card">
              {platformGoal &&
                accountIdGoal &&
                metricGoal &&
                monthlyGoal > 0 && (
                  <GoalChart
                    platform={platformGoal}
                    accountId={accountIdGoal}
                    metric={metricGoal}
                    monthlyGoal={monthlyGoal}
                  />
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
