import React, { useState } from "react";
import { FaInstagram, FaImages, FaSortAmountDown } from "react-icons/fa";
import { formatNumber } from "./FormatNumber";
import moment from "moment";

const MediaTypeComparison = ({ competitors }) => {
  const [sortOrder, setSortOrder] = useState("none");

  const periodOptions = {
    30: {
      start: moment().subtract(30, "days").startOf("day"),
      end: moment().startOf("day"),
    },
    60: {
      start: moment().subtract(60, "days").startOf("day"),
      end: moment().startOf("day"),
    },
    90: {
      start: moment().subtract(3, "months").startOf("day"),
      end: moment().startOf("day"),
    },
    180: {
      start: moment().subtract(6, "months").startOf("day"),
      end: moment().startOf("day"),
    },
  };

  // You can also have a state that holds the key of the currently selected period option:
  const [selectedPeriodKey, setSelectedPeriodKey] = useState("30");

  // And a state that holds the actual date range based on the selected option:
  const [selectedPeriod, setSelectedPeriod] = useState(
    periodOptions[selectedPeriodKey]
  );
  const calculateTotalMediaCount = (mediaCounts) => {
    return mediaCounts.reels + mediaCounts.carousels + mediaCounts.photos;
  };

  const calculateMediaCount = (mediaArray = [], { start, end }) => {
    return mediaArray.filter((media) => {
      const timestamp = moment(media.timestamp, "YYYY-MM-DD HH:mm:ss");
      return timestamp.isBetween(start, end, undefined, "[]");
    }).length;
  };

  const calculateMediaGrowth = (current, previous) => {
    if (selectedPeriod === "180") {
      return "n/a";
    }
    if (previous === 0) {
      return current > 0 ? "New" : "n/a";
    }
    return ((current - previous) / previous) * 100;
  };

  const calculatePreviousPeriod = (currentPeriod) => {
    // Calculate the length of the current period in days
    const currentPeriodLength = currentPeriod.end.diff(
      currentPeriod.start,
      "days"
    );

    // End of the previous period is one day before the start of the current period
    const previousEnd = moment(currentPeriod.start).subtract(1, "days");

    // Start of the previous period is the same length as the current period before the previous end
    const previousStart = moment(previousEnd).subtract(
      currentPeriodLength,
      "days"
    );

    return {
      start: previousStart,
      end: previousEnd,
    };
  };

  const competitorsWithMediaCount = competitors.map((competitor) => {
    const currentPeriodCount = {
      reels: calculateMediaCount(competitor.reels, selectedPeriod),
      carousels: calculateMediaCount(competitor.carousels, selectedPeriod),
      photos: calculateMediaCount(competitor.photos, selectedPeriod),
    };
    const currentPeriod = periodOptions[selectedPeriodKey];
    const previousPeriod = calculatePreviousPeriod(currentPeriod);

    const previousPeriodCount = {
      reels: calculateMediaCount(competitor.reels, previousPeriod),
      carousels: calculateMediaCount(competitor.carousels, previousPeriod),
      photos: calculateMediaCount(competitor.photos, previousPeriod),
    };

    const currentTotal = calculateTotalMediaCount(currentPeriodCount);
    const previousTotal = calculateTotalMediaCount(previousPeriodCount);
    const totalGrowth = calculateMediaGrowth(currentTotal, previousTotal);

    return {
      ...competitor,
      currentPeriodCount,
      previousPeriodCount,
      currentTotal,
      totalGrowth,
    };
  });

  const parseGrowthValue = (growth) => {
    return growth === "n/a" || growth === "New" ? 0 : parseFloat(growth);
  };

  let sortedCompetitors;
  if (sortOrder !== "none") {
    sortedCompetitors = [...competitorsWithMediaCount].sort((a, b) => {
      let growthA = parseGrowthValue(a.totalGrowth);
      let growthB = parseGrowthValue(b.totalGrowth);
      return sortOrder === "ascending" ? growthA - growthB : growthB - growthA;
    });
  } else {
    sortedCompetitors = competitorsWithMediaCount;
  }

  const handleSortClick = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === "none") return "ascending";
      if (prevOrder === "ascending") return "descending";
      return "none";
    });
  };

  const handlePeriodChange = (event) => {
    const newPeriodKey = event.target.value;
    setSelectedPeriodKey(newPeriodKey);
    setSelectedPeriod(periodOptions[newPeriodKey]);
  };

  const maxMediaCount = Math.max(
    ...sortedCompetitors.map(
      (comp) =>
        comp.currentPeriodCount.reels +
        comp.currentPeriodCount.carousels +
        comp.currentPeriodCount.photos
    )
  );

  const totalDays = selectedPeriod.end.diff(selectedPeriod.start, "days");
  const totalMonths = totalDays / 30;

  // Calculate the max average reels once before mapping over the competitors
  const maxAverageReels = Math.max(
    ...competitorsWithMediaCount.map((comp) => {
      const reelsTotal = comp.currentPeriodCount.reels;
      return totalMonths > 0 ? reelsTotal / totalMonths : 0;
    })
  );

  return (
    <div className="competitor-comparison">
      <h3>Media mix</h3>
      <div className="table__header">
        <div className="color__media">
          <p>Image</p>
          <p>Carousels</p>
          <p>Reels</p>
        </div>
        <div className="date__range">
          <select value={selectedPeriodKey} onChange={handlePeriodChange}>
            <option value="30">Last 30 days</option>
            <option value="60">Last 60 days</option>
            <option value="90">Last 3 months</option>
            <option value="180">Last half year</option>
          </select>
        </div>
      </div>
      <div className="followers">
        <table>
          <thead>
            <tr>
              <th>
                <FaInstagram size={15} />
              </th>
              <th>
                <FaImages size={15} />
              </th>
              <th className="sort" onClick={handleSortClick}>
                <FaSortAmountDown size={15} color="#484848" />
              </th>
              <th className="table__desc">Media type</th>
            </tr>
          </thead>
          <tbody>
            {sortedCompetitors.map((competitor, key) => {
              const { currentPeriodCount, totalGrowth, currentTotal } =
                competitor;

              // Determine the border radius for each segment
              const leftRadius =
                currentPeriodCount.photos > 0
                  ? "10px"
                  : currentPeriodCount.carousels > 0
                  ? "10px"
                  : "10px";

              return (
                <tr key={key}>
                  <td className="username__td">{competitor.username}</td>
                  <td className="followers__td">
                    {formatNumber(currentTotal)}
                  </td>
                  <td
                    className="media-growth followers__td"
                    style={{
                      color:
                        totalGrowth > 0
                          ? "green"
                          : totalGrowth < 0
                          ? "red"
                          : "black",
                    }}
                  >
                    {isNaN(totalGrowth) ? "N/A" : `${totalGrowth.toFixed(0)}%`}
                  </td>
                  <td className="media-bar-holder">
                    <div className="media-bar">
                      {currentPeriodCount.photos > 0 && (
                        <div
                          className="bar-type photos"
                          style={{
                            width: `${
                              (currentPeriodCount.photos / maxMediaCount) * 100
                            }%`,
                            borderTopLeftRadius: leftRadius,
                            borderBottomLeftRadius: leftRadius,
                          }}
                        ></div>
                      )}
                      {currentPeriodCount.carousels > 0 && (
                        <div
                          className="bar-type carousels"
                          style={{
                            width: `${
                              (currentPeriodCount.carousels / maxMediaCount) *
                              100
                            }%`,
                            borderTopLeftRadius:
                              currentPeriodCount.photos === 0 ? "10px" : "0",
                            borderBottomLeftRadius:
                              currentPeriodCount.photos === 0 ? "10px" : "0",
                            borderTopRightRadius:
                              currentPeriodCount.reels === 0 ? "10px" : "0",
                            borderBottomRightRadius:
                              currentPeriodCount.reels === 0 ? "10px" : "0",
                          }}
                        ></div>
                      )}
                      {currentPeriodCount.reels > 0 && (
                        <div
                          className="bar-type reels"
                          style={{
                            width: `${
                              (currentPeriodCount.reels / maxMediaCount) * 100
                            }%`,
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        ></div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="split">
          <h4>Avg reels per month</h4>
          <div className="split__table">
            {sortedCompetitors.map((competitor, key) => {
              const username = competitor.username || "n/a";
              const reelsTotal = competitor.currentPeriodCount.reels;
              const averageReels =
                totalMonths > 0 ? (reelsTotal / totalMonths).toFixed(0) : 0;

              // Calculate the height percentage of the bar for the competitor
              const barHeightPercent = (averageReels / maxAverageReels) * 100;

              return (
                <div className="split__account" key={key}>
                  <div className="pillar-container">
                    <div
                      className="pillar"
                      style={{
                        height: `${barHeightPercent}%`,
                        backgroundColor: "purple",
                      }}
                    >
                      <small className="avg__reels">{averageReels}</small>
                    </div>
                  </div>
                  <small className="avg__username">{username}</small>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaTypeComparison;
