import React from "react";
import { VictoryLine, VictoryChart, VictoryAxis, VictoryTheme } from "victory";

const FollowerGoalChart = ({
  followerGoal,
  currentFollowers,
  followGoalGain,
  currentFollowGain,
  monthsRemaining,
}) => {
  // Helper function to add months
  const addMonths = (date, months) => {
    date.setMonth(date.getMonth() + months);
    return date;
  };

  // Generate data for both lines
  const projectedData = [];
  const goalData = [];
  let maxYValue = currentFollowers; // Initialize with current followers

  for (let i = 0; i <= monthsRemaining; i++) {
    const newDate = addMonths(new Date(), i);

    // Projected growth
    const projectedFollowers = currentFollowers + currentFollowGain * i;
    projectedData.push({ x: newDate, y: projectedFollowers });

    // Goal
    const goalFollowers = currentFollowers + followGoalGain * i;
    goalData.push({ x: newDate, y: goalFollowers });

    // Update maxYValue to ensure it holds the maximum y value encountered
    maxYValue = Math.max(maxYValue, projectedFollowers, goalFollowers);
  }

  const domain = {
    y: [currentFollowers, Math.max(maxYValue, followerGoal)], // Ensure domain accommodates the highest value
  };

  // Generate month ticks based on remaining months
  const monthTicks = projectedData.map((dataPoint) => dataPoint.x);

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      scale={{ x: "time" }}
      domain={domain}
      width={500}
    >
      <VictoryLine
        data={projectedData}
        style={{
          data: { stroke: "#bcb1f8" },
        }}
      />
      <VictoryLine
        data={goalData}
        style={{
          data: { stroke: "#f1c40f" },
        }}
      />
      <VictoryAxis
        tickValues={monthTicks}
        tickFormat={(t) => `${t.toLocaleString("default", { month: "short" })}`}
        fixLabelOverlap
      />
      <VictoryAxis dependentAxis />
    </VictoryChart>
  );
};

export default FollowerGoalChart;
