import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_ACCOUNTS } from "../modules/queries.js";
import { FaRegCopy, FaCheck } from "react-icons/fa"; // Import the checkmark icon

export default function Seed() {
  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData,
  } = useQuery(GET_USER_ACCOUNTS);

  const accounts = accountsData?.getUserAccounts;
  const [copiedAccountId, setCopiedAccountId] = useState(null);

  const hasSeedAccount = accounts?.some(
    (account) => account.platform === "seed"
  );

  const generateScriptTag = (account) => {
    console.log(account);

    // Building script tag dynamically based on account properties
    let scriptParts = [
      "<!-- Seed tag -->",
      "<script",
      "  defer",
      `  src="https://grow.growhouse.co/seed/seed.min.js"`,
      `  data-token="${account._id}"`,
      `  data-site="${account.accountId}"`,
    ];

    if (account.seed.point && account.seed.point !== "") {
      scriptParts.push(`  data-point="${account.seed.point}"`);
    }

    if (account.seed.checkoutUrl && account.seed.checkoutUrl !== "") {
      scriptParts.push(`  data-checkout="${account.seed.checkoutUrl}"`);
    }

    if (
      account.seed.subscriptionBtnId &&
      account.seed.subscriptionBtnId !== ""
    ) {
      scriptParts.push(
        `  data-subscription-btn-id="${account.seed.subscriptionBtnId}"`
      );
    }

    scriptParts.push("></script>");

    const plainScript = scriptParts.join("\n");

    // Creating the HTML script representation
    let htmlScriptParts = [
      '<pre style="line-height: 1.6; letter-spacing: 0.5px;">',
      "  <code>",
      '    <span style="color: #6a9955;"><!-- Seed tag --></span>',
      '    <span style="color: #808080;">&lt;</span><span style="color: #d16969;">script</span>',
      '    <span style="color: #569cd6;">defer</span>',
      '    <span style="color: #569cd6;">src</span><span style="color: #808080;">=</span><span style="color: #808080;">"</span><span style="color: #d0bb56;">https://grow.growhouse.co/seed/seed.min.js</span><span style="color: #808080;">"</span>',
      '    <span style="color: #569cd6;">data-token</span><span style="color: #808080;">=</span><span style="color: #808080;">"</span><span style="color: #d0bb56;">' +
        account._id +
        '</span><span style="color: #808080;">"</span>',
    ];

    if (account.seed.point && account.seed.point !== "") {
      htmlScriptParts.push(
        `    <span style="color: #569cd6;">data-point</span><span style="color: #808080;">=</span><span style="color: #808080;">"</span><span style="color: #d0bb56;">${account.seed.point}</span><span style="color: #808080;">"</span>`
      );
    }

    if (account.accountId) {
      htmlScriptParts.push(
        `    <span style="color: #569cd6;">data-site</span><span style="color: #808080;">=</span><span style="color: #808080;">"</span><span style="color: #d0bb56;">${account.accountId}</span><span style="color: #808080;">"</span>`
      );
    }

    if (account.seed.checkoutUrl && account.seed.checkoutUrl !== "") {
      htmlScriptParts.push(
        `    <span style="color: #569cd6;">data-checkout</span><span style="color: #808080;">=</span><span style="color: #808080;">"</span><span style="color: #d0bb56;">${account.seed.checkoutUrl}</span><span style="color: #808080;">"</span>`
      );
    }
    if (
      account.seed.subscriptionBtnId &&
      account.seed.subscriptionBtnId !== ""
    ) {
      htmlScriptParts.push(
        `    <span style="color: #569cd6;">data-subscription-btn-id</span><span style="color: #808080;">=</span><span style="color: #808080;">"</span><span style="color: #d0bb56;">${account.seed.subscriptionBtnId}</span><span style="color: #808080;">"</span>`
      );
    }
    htmlScriptParts.push(
      '    <span style="color: #808080;">&gt;&lt;/</span><span style="color: #d16969;">script</span><span style="color: #808080;">&gt;</span>',
      "  </code>",
      "</pre>"
    );

    const htmlScript = htmlScriptParts.join("\n");

    return {
      plainScript,
      htmlScript,
    };
  };

  const copyToClipboard = (plainScript, accountId) => {
    navigator.clipboard.writeText(plainScript).then(
      () => {
        setCopiedAccountId(accountId);
        setTimeout(() => setCopiedAccountId(null), 2000);
      },
      (err) => {
        console.error("Failed to copy!", err);
      }
    );
  };

  if (accountsLoading) return <p>Loading...</p>;
  if (accountsError) return <p>Error :(</p>;

  return (
    <div className="dashboard">
      {hasSeedAccount ? (
        accounts
          .filter((account) => account.platform === "seed")
          .map((account, index) => {
            const { plainScript, htmlScript } = generateScriptTag(account);
            return (
              <div className="seed__pixel" key={account._id}>
                <h2>Your 🌱</h2>
                <div className="seed__script-container">
                  <div dangerouslySetInnerHTML={{ __html: htmlScript }} />
                  <button
                    className="seed__copy-button"
                    onClick={() => copyToClipboard(plainScript, account._id)}
                    title="Copy to clipboard"
                  >
                    {copiedAccountId === account._id ? (
                      <FaCheck size={20} />
                    ) : (
                      <FaRegCopy size={20} />
                    )}
                  </button>
                </div>
              </div>
            );
          })
      ) : (
        <div className="seed__form">
          <div className="seed__form-header">
            <h2>Create your 🌱</h2>
            <input type="text" placeholder="Name your seedling" />
            <input
              type="text"
              placeholder="Enter full url including https://"
            />
            <input
              type="text"
              placeholder="Enter proxy sub domain for 1st party"
            />
            <input type="text" placeholder="Successful checkout url" />
            <button>Create</button>
          </div>
        </div>
      )}
    </div>
  );
}
