export function formatNumber(num) {
  if (isNaN(num)) {
    return "N/A";
  } else if (num < 1000) {
    return num; // return the original number if less than 1000
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to 'k' for thousands
  } else {
    return (num / 1000000).toFixed(1) + "M"; // convert to 'M' for millions
  }
}

export function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
