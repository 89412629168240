import axios from "axios";

const currentUrl = window.location.href;

let baseUrl;
if (currentUrl.includes("grow.")) {
  baseUrl = "https://grow.growhouse.co"; // Development URL
} else {
  baseUrl = "https://growhousetest.com"; // Production URL
}
console.log("baseUrl:", baseUrl);
const api = axios.create({
  baseURL: `${baseUrl}/api`,
  withCredentials: true,
});

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Handle the error, e.g., by setting global state or showing a notification
//     if (error.response && error.response.data && error.response.data.error) {
//       // Specific error from the server
//       alert("Crashtest, " + error.message);
//     } else if (error.response) {
//       // There is an error response but no specific error message, show the status
//       alert(
//         "Crashtest: An unknown error with response status: " +
//           error.response.status +
//           "and error response" +
//           error.response
//       );
//     } else if (error.request) {
//       // The request was made but no response was received
//       alert("Crashtest: The request was made but no response was received");
//     } else {
//       // Something happened in setting up the request that triggered an error
//       alert("Crashtest: No clue what happened..");
//     }
//   }
// );

export default api;
