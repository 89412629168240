export const convertMetricToModelName = (platform, metric) => {
  if (platform.toLowerCase() === "instagram") {
    // Split the metric into words, capitalize the first letter of each word, and join them
    let modelName = "Ig";

    const camelCaseMetric = metric
      .split("_")
      .map((word, index) =>
        index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");

    const directMetrics = ["followers", "profileViews", "websiteClicks"];

    // Check if the converted metric is one of the direct metrics
    if (directMetrics.includes(camelCaseMetric)) {
      modelName +=
        camelCaseMetric.charAt(0).toUpperCase() + camelCaseMetric.slice(1);
    } else {
      // For other metrics, append "MediaProductType"
      modelName +=
        camelCaseMetric.charAt(0).toUpperCase() +
        camelCaseMetric.slice(1) +
        "MediaProductType";
    }
    // Add more platforms if necessary
    console.log("Model name:", modelName);
    return modelName;
  } else {
    console.log("Metric name:", metric);
    return metric;
  }
};

export const generateColor = (index) => {
  try {
    const colors = [
      "#ff4e4e", // Smooth red
      "#4caf50", // Green
      "#2196f3", // Blue
      "#ff9800", // Orange
      "#9c27b0", // Purple
      "#e91e63", // Pink
      "#009688", // Teal
      "#795548", // Brown
      "#9e9e9e", // Gray
      "#00bcd4", // Cyan
    ];

    // If the index is greater than the number of colors, cycle through the colors
    const colorIndex = index % colors.length;

    // Return the color
    return colors[colorIndex];
  } catch (error) {
    console.log("Error generating color:", error);
  }
};

export function formatNumber(num) {
  if (isNaN(num)) {
    return "N/A";
  } else if (num < 1000) {
    return num; // return the original number if less than 1000
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to 'k' for thousands
  } else {
    return (num / 1000000).toFixed(1) + "M"; // convert to 'M' for millions
  }
}
