// queries.js
import { gql } from "@apollo/client";

export const GET_USER_ACCOUNTS = gql`
  query GetUserAccounts {
    getUserAccounts {
      _id
      accountId
      username
      name
      platform
      availableMetrics
      seed {
        checkoutUrl
        subscriptionBtnId
        point
      }
    }
  }
`;

export const GET_METRICS = gql`
  query GetMetrics($platform: String!, $accountId: String!, $metric: String!) {
    metrics(platform: $platform, accountId: $accountId, metric: $metric) {
      platform
      accountId
      metric
      username
      startDate
      endDate
      isChecked
      showMedia
      data {
        date
        value
      }
      ads {
        date
        value
      }
    }
  }
`;

export const GET_GROUPED_METRICS = gql`
  query GetGroupedMetrics($conditions: [MetricCondition]!) {
    groupedMetrics(conditions: $conditions) {
      accountId
      platform
      metric
      username
      data {
        nonBreakdown {
          date
          value
        }
        breakdowns {
          key
          values {
            date
            value
          }
        }
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;

export const MetricConditionInput = gql`
  input MetricCondition {
    platform: String!
    accountId: String!
    metric: String!
  }
`;

export const GET_USER = gql`
  query GetUser($email: String) {
    getUser(email: $email) {
      goals {
        platform
        accountId
        metric
        monthlyGoal
        _id
      }
    }
  }
`;

export const UPLOAD_USER_MUTATION = gql`
  mutation uploadUser(
    $email: String!
    $roles: String!
    $status: String!
    $company: String
    $igAccounts: [String]
  ) {
    uploadUser(
      email: $email
      roles: $roles
      status: $status
      company: $company
      igAccounts: $igAccounts
    ) {
      id
    }
  }
`;

export const UPLOAD_GOAL_MUTATION = gql`
  mutation AddGoal($goalInput: GoalInput!) {
    addGoal(goalInput: $goalInput) {
      goals {
        platform
        accountId
        metric
        monthlyGoal
      }
    }
  }
`;

export const DELETE_GOAL_MUTATION = gql`
  mutation DeleteGoal($goalId: ID!) {
    deleteGoal(goalId: $goalId) {
      success
      message
    }
  }
`;

export const GET_MEDIA = gql`
  query GET_MEDIA($accountId: String!, $platform: String!) {
    Media(accountId: $accountId, platform: $platform) {
      media_id
      published_date
      media_type
      media_url
      thumbnail_url
      permalink
      accountId
    }
  }
`;

export const GET_MEDIA_METRICS = gql`
  query GET_MEDIA_METRICS($accountId: String!, $mediaId: ID!) {
    MediaMetrics(accountId: $accountId, mediaId: $mediaId) {
      accountId
      published_date
      media_type
      media_url
      thumbnail_url
      title
      total_views
      unique_views
      likes
      comments
      shares
      saved
      total_interactions
    }
  }
`;

///----------------_OLD STUFF_------------------///

export const GET_GA4_ACCOUNTS = gql`
  query GetGa4Accounts {
    getGa4Accounts {
      propertyId
      displayName
      currencyCode
      availableMetrics
    }
  }
`;

export const GET_GA4_VISITORS = gql`
  query GetGa4Visitors($propertyId: String!, $metric: String!) {
    getGa4Visitors(propertyId: $propertyId, metric: $metric) {
      propertyId
      name
      startDate
      endDate
      data {
        date
        value
      }
    }
  }
`;

export const GET_IG_METRICS = gql`
  query Query(
    $metric: String!
    $instagramAccountId: String!
    $startDate: String!
    $endDate: String!
  ) {
    getIgMetrics(
      metric: $metric
      instagramAccountId: $instagramAccountId
      startDate: $startDate
      endDate: $endDate
    ) {
      name
      current {
        date
        value
      }
      previous {
        date
        value
      }
    }
  }
`;

export const GET_IG_ORGANIC_DATA = gql`
  query getIgOrganicData($metric: String!, $instagramAccountId: String!) {
    getIgOrganicData(metric: $metric, instagramAccountId: $instagramAccountId) {
      instagramAccountId
      name
      startDate
      endDate
      data {
        date
        value
      }
      ads {
        date
        value
      }
    }
  }
`;

export const GET_IG_METRIC_DATA = gql`
  query GetIgMetricsData($metric: String!, $instagramAccountId: String!) {
    getIgMetricsData(metric: $metric, instagramAccountId: $instagramAccountId) {
      instagramAccountId
      name
      startDate
      endDate
      data {
        date
        value
      }
    }
  }
`;

export const GET_USER_STRIPE_ACCOUNTS = gql`
  query GetStripeAccounts {
    getStripeAccounts {
      _id
      userId
      stripeAccountId
      username
      timezone
      currency
      availableMetrics
    }
  }
`;

export const GET_USER_STRIPE_STATS = gql`
  query GetUserCharges($userId: ID, $accountId: String) {
    getUserCharges(userId: $userId, accountId: $accountId) {
      stripeAccountId
      name
      currency
      data {
        date
        value
        products {
          name
          quantity
        }
      }
      startDate
      endDate
    }
  }
`;

export const GET_IG_BREAKDOWN_DATA = gql`
  query GetInstagramBreakdownData(
    $instagramAccountId: String!
    $metric: String!
  ) {
    getInstagramBreakdownData(
      instagramAccountId: $instagramAccountId
      metric: $metric
    ) {
      instagramAccountId
      name
      dimensions {
        dimensionValue
        data {
          date
          value
        }
      }
    }
  }
`;
