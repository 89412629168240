import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_METRICS } from "../modules/queries.js";
import { convertMetricToModelName, formatNumber } from "./Converter.js";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLabel,
} from "victory";
import moment from "moment";
import "../style/Dashboard.css";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";

const ProgressBar = ({ current, goal, dailyGoal, daysPassed }) => {
  const progressBarWidth = "100%"; // Full width for the monthly goal
  const currentProgressWidth = `${Math.min(current / goal, 1) * 100}%`;

  // Calculate where we should be by now (projection)
  const shouldHaveReachedByNow = dailyGoal * daysPassed;
  const projectionWidth = `${(shouldHaveReachedByNow / goal) * 100}%`;

  // Determine the color for the current progress
  const isOnTrackOrAhead = current >= shouldHaveReachedByNow;
  const currentProgressColor = isOnTrackOrAhead
    ? "rgb(3 137 1 /  100%)"
    : "red";
  const amountAheadOrShort =
    current - shouldHaveReachedByNow < 0
      ? shouldHaveReachedByNow - current
      : current - shouldHaveReachedByNow;

  // Set a less prominent color for the projected progress
  const projectionColor = isOnTrackOrAhead ? "rgb(0 109 0)" : "#bcbaba";
  // Calculate the percentage reached for display
  const percentageReached = ((current / goal) * 100).toFixed(1) + "%";

  const lineLeftPosition = `calc(${currentProgressWidth} - 1px)`; // Adjust this as needed to align with the text
  const goalLinePosition = `calc(${projectionWidth} - 1px)`;
  return (
    <div
      style={{
        position: "relative",
        width: progressBarWidth,
        height: "20px",
        backgroundColor: "#e0e0e0",
        borderRadius: "5px",
      }}
    >
      {/* Projection bar (less prominent) */}
      <div
        style={{
          position: "absolute",
          width: projectionWidth,
          backgroundColor: projectionColor,
          height: "100%",
          borderRadius: "5px",
        }}
      />
      {/* Current progress bar (more prominent) */}
      <div
        style={{
          position: "absolute",
          width: currentProgressWidth,
          backgroundColor: currentProgressColor,
          height: "100%",
          borderRadius: "5px",
          zIndex: 1,
          transition: "width 0.5s ease",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          fontSize: "10px",
        }}
      >
        {percentageReached}
      </div>
      <div
        style={{
          position: "absolute",
          left: lineLeftPosition,
          top: "100%",
          borderLeft: `1px solid ${currentProgressColor}`, // Adjust the color as needed
          height: "14px", // Adjust the length as needed
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          left: goalLinePosition,
          top: "100%",
          borderLeft: `1px solid ${projectionColor}`, // Adjust the color as needed
          height: "14px", // Adjust the length as needed
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          left: projectionWidth,
          top: "100%",
          marginTop: "4px", // Small space below the progress bar
          color: projectionColor,
          marginLeft: "4px", // Small space to the right of the progress bar
          fontSize: "8px",
        }}
      >
        {formatNumber(shouldHaveReachedByNow)}
      </div>
      {/* I need help to add another stroke that's in the end of the progress bar */}
      {/* <div
        style={{
          position: "absolute",
          right: 0,
          top: "100%",
          borderLeft: `1px solid green`, // Adjust the color as needed
          height: "14px", // Adjust the length as needed
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          right: 0,
          top: "100%",
          marginTop: "4px", // Small space below the progress bar
          color: "green",
          marginRight: "-14%", // Small space to the right of the progress bar
          fontSize: "8px",
        }}
      >
        {formatNumber(goal)} goal
      </div> */}

      <div
        style={{
          position: "absolute",
          left: currentProgressWidth,
          top: "100%",
          marginTop: "4px", // Small space below the progress bar
          color: currentProgressColor,
          marginLeft: "4px", // Small space to the right of the progress bar
          fontSize: "8px",
        }}
      >
        {formatNumber(current)}{" "}
      </div>
    </div>
  );
};

const sumValuesForPeriod = (data, start, end) => {
  return data
    .filter((d) => {
      const date = moment(d.date);
      return date.isSameOrAfter(start) && date.isSameOrBefore(end);
    })
    .reduce((acc, d) => acc + d.value, 0);
};

const countMediaTypes = (mediaArray, periodStart, periodEnd) => {
  // Initialize the counts
  const count = { reels: 0, carousels: 0, images: 0 };

  // Loop over each media item
  mediaArray.forEach((item) => {
    const itemDate = moment(parseInt(item.timestamp));

    // Check if the item's date falls within the period
    if (
      itemDate.isSameOrAfter(periodStart) &&
      itemDate.isSameOrBefore(periodEnd)
    ) {
      switch (item.media_type) {
        case "reels":
          count.reels += 1;
          break;
        case "carousel":
          count.carousels += 1;
          break;
        case "image":
          count.images += 1;
          break;
        // Add additional cases for other media types if necessary
      }
    }
  });

  return count;
};

export default function GoalChart({
  platform,
  accountId,
  metric,
  monthlyGoal,
}) {
  const [data, setData] = useState(null);

  const [
    getMetrics,
    { loading: metricsLoading, data: metricsData, error: metricsError },
  ] = useLazyQuery(GET_METRICS);

  useEffect(() => {
    if (platform && accountId && metric && monthlyGoal > 0) {
      getMetrics({
        variables: {
          platform: platform,
          accountId: accountId,
          metric: metric,
        },
      });
    }
  }, [platform, accountId, metric, monthlyGoal]);

  useEffect(() => {
    if (metricsData) {
      console.log("Metrics data:", metricsData);
      setData(metricsData.metrics.data);
    }
  }, [metricsData]);

  if (metricsLoading) return "Loading...";
  if (metricsError) return `Error! ${metricsError.message}`;
  if (!data) return "Waiting for data...";

  const yesterday = moment().subtract(1, "days");
  const startOfCurrentMonth = moment().startOf("month");
  const currentMonthName = moment().format("MMMM"); // 'February' if the current month is February
  const currentYear = moment().year();
  const endOfCurrentMonth = moment().endOf("month").toDate();
  const startOfPreviousMonth = moment().subtract(1, "month").startOf("month");
  const startOfLastYearSamePeriod = moment()
    .subtract(1, "year")
    .startOf("month");
  const endOfPreviousPeriod = moment()
    .subtract(1, "month")
    .date(yesterday.date());
  const endOfLastYearSamePeriod = moment()
    .subtract(1, "year")
    .date(yesterday.date());
  const daysPassed = yesterday.date(); // Number of days passed in the current month

  // Total value for the current month
  const totalValueCurrentMonth = sumValuesForPeriod(
    data,
    startOfCurrentMonth,
    yesterday
  );

  // Total value for the previous period (1st to yesterday's date last month)
  const totalValuePreviousPeriod = sumValuesForPeriod(
    data,
    startOfPreviousMonth,
    endOfPreviousPeriod
  );

  // Total value for the same period last year
  const totalValueLastYearSamePeriod = sumValuesForPeriod(
    data,
    startOfLastYearSamePeriod,
    endOfLastYearSamePeriod
  );

  const daysInCurrentMonth = moment().daysInMonth();
  const dailyGoal = Math.round(monthlyGoal / daysInCurrentMonth);

  // Growth calculation
  const growthComparedToPreviousMonth = parseFloat(
    (
      ((totalValueCurrentMonth - totalValuePreviousPeriod) /
        totalValuePreviousPeriod) *
      100
    ).toFixed(1)
  );

  const growthComparedToLastYear = parseFloat(
    (
      ((totalValueCurrentMonth - totalValueLastYearSamePeriod) /
        totalValueLastYearSamePeriod) *
      100
    ).toFixed(1)
  );

  // Filter the data for the ongoing month to display in the graph
  const ongoingMonthData = data.filter((d) =>
    moment(d.date).isBetween(startOfCurrentMonth, yesterday, null, "[]")
  );

  const formattedOngoingMonthData = ongoingMonthData.map((d) => ({
    x: moment(d.date).toDate(),
    y: d.value,
  }));

  const minDataValue = Math.min(...formattedOngoingMonthData.map((d) => d.y));
  const maxDataValue = Math.max(...formattedOngoingMonthData.map((d) => d.y));

  // Determine the range of the data and calculate a margin
  const dataRange = maxDataValue - minDataValue;
  const margin = dataRange * 0.1; // 10% margin of the data range

  // Adjust the y-axis domain to ensure the daily goal line is always visible
  // Use the margin to ensure there is space above the highest data point or below the lowest data point
  const minYAxisValue =
    dailyGoal < minDataValue ? dailyGoal - margin : minDataValue - margin;
  const maxYAxisValue =
    dailyGoal > maxDataValue ? dailyGoal + margin : maxDataValue + margin;

  const chartHeight = 250;
  const topPadding = 60; // The top padding you set in your VictoryChart
  const bottomPadding = 50; // The bottom padding you set in your VictoryChart
  const effectiveHeight = chartHeight - topPadding - bottomPadding; // Effective drawing area height

  // Calculate the ratio of how far the dailyGoal is within the domain range
  const ratio = (dailyGoal - minYAxisValue) / (maxYAxisValue - minYAxisValue);

  // Calculate labelYPosition based on the effectiveHeight and ratio
  // This will align the label with the dailyGoal line
  const labelYPosition = topPadding + (1 - ratio) * effectiveHeight;
  const shouldHaveReachedByNow = dailyGoal * daysPassed;
  const amountAheadOrShort =
    totalValueCurrentMonth - shouldHaveReachedByNow < 0
      ? shouldHaveReachedByNow - totalValueCurrentMonth
      : totalValueCurrentMonth - shouldHaveReachedByNow;
  const victoryTooltip = (
    <VictoryTooltip
      flyoutStyle={{
        stroke: "#e3e3e3",
        fill: "white",
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
      }}
      style={{ fontSize: 10 }}
    />
  );

  console.log(formatNumber(growthComparedToLastYear));
  return (
    <div className="goal-inline-grid">
      <div className="goal-left">
        <p className="progress-p">
          {platform ? platform.charAt(0).toUpperCase() + platform.slice(1) : ""}{" "}
          <strong>{metric}</strong>{" "}
        </p>
        <p className="progress-d">
          {moment(startOfCurrentMonth).format("MMM Do")} -{" "}
          {moment().format("MMM Do")}
        </p>
        <div style={{ marginTop: "0px", height: "95%" }}>
          <VictoryChart
            padding={{ top: 0, bottom: 0, left: 50, right: 50 }}
            theme={VictoryTheme.material}
            scale={{ x: "time", y: "linear" }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => `${datum.y}`}
                labelComponent={victoryTooltip}
              />
            }
            width={500}
            height={chartHeight}
            domain={{
              x: [startOfCurrentMonth, endOfCurrentMonth], // Domain for x is the current month
              y: [minYAxisValue, maxYAxisValue], // Adjusted domain for y
            }}
          >
            {/* <VictoryLabel
            x={10}
            y={20}
            text={`${currentMonthName} ${currentYear}`}
            style={{
              fill: "#333", // Or any color you prefer
              fontSize: 15, // Increase font size as needed
            }}
          /> */}
            <VictoryAxis
              tickLabelComponent={<VictoryLabel dy={-5} />}
              fixLabelOverlap={true}
              tickFormat={(x) => moment(x).format("MMM DD")} // Format x-axis ticks to show month and day
              style={{
                tickLabels: { fill: "black", fontSize: 8 }, // Tick label color and font size
              }}
            />
            <VictoryAxis
              axisLabelComponent={<VictoryLabel dy={20} />}
              dependentAxis
              fixLabelOverlap={true}
              style={{
                tickLabels: { fill: "black", fontSize: 8 }, // Tick label color and font size
              }}
            />
            <VictoryLine
              data={[
                { x: startOfCurrentMonth, y: dailyGoal },
                { x: endOfCurrentMonth, y: dailyGoal },
              ]}
              style={{
                data: {
                  stroke: "grey",
                  strokeWidth: 2,
                  strokeDasharray: "5,5",
                },
              }}
            />
            <VictoryLabel
              text={`Daily goal`}
              x={420} // You might need to adjust this to position the label correctly
              y={labelYPosition} // You might need to adjust this to position the label correctly
              textAnchor={"middle"}
              style={{ fontSize: 15, fill: "grey" }} // Adjust styling as needed
            />
            <VictoryLine
              data={formattedOngoingMonthData}
              interpolation={"basis"}
              style={{
                data: { stroke: "#0b4c37", strokeWidth: 2 }, // Line color
              }}
            />
          </VictoryChart>
        </div>
      </div>
      <div className="goal-right-grid">
        <div className="goal-stats-grid">
          <div className="goal-grid-content monthly-goal">
            <h4>Monthly Goal</h4>
            <div className="goal-values">
              <div>
                <h3>{formatNumber(monthlyGoal)}</h3>
                <p
                  className="status-goal-p"
                  style={
                    totalValueCurrentMonth - shouldHaveReachedByNow > 0
                      ? { color: "#03ff03" }
                      : { color: "red" }
                  }
                >
                  {totalValueCurrentMonth - shouldHaveReachedByNow > 0 ? (
                    <FaArrowCircleUp />
                  ) : (
                    <FaArrowCircleDown />
                  )}
                  {formatNumber(amountAheadOrShort)}{" "}
                  {totalValueCurrentMonth - shouldHaveReachedByNow > 0
                    ? "ahead"
                    : "short"}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="goal-comp">
            <div className="goal-comp-content">
              <h2>Month to date</h2>
              <div className="comp-growth">
                <p>{formatNumber(totalValueCurrentMonth)}</p>
                <p></p>
              </div>
            </div>
            <div className="goal-comp-content">
              <h2>Last year (same period)</h2>
              <div className="comp-growth">
                <p>
                  {formatNumber(totalValueLastYearSamePeriod) <= 0
                    ? "N/A"
                    : formatNumber(totalValueLastYearSamePeriod)}
                </p>
                <p
                  style={{
                    color:
                      formatNumber(growthComparedToLastYear) === "InfinityM"
                        ? "white"
                        : `${
                            growthComparedToLastYear >= 0 ? "#03ff03" : "red"
                          }`,
                  }}
                  className="growth__p"
                >
                  {formatNumber(growthComparedToLastYear) === "InfinityM"
                    ? "N/A"
                    : formatNumber(growthComparedToLastYear) + "%"}
                </p>
              </div>
            </div>
            <div className="goal-comp-content">
              <h2>Previous period</h2>
              <div className="comp-growth">
                <p>{formatNumber(totalValuePreviousPeriod)}</p>

                <p
                  style={{
                    color: `${
                      growthComparedToPreviousMonth >= 0 ? "#03ff03" : "red"
                    }`,
                  }}
                  className="growth__p"
                >
                  {formatNumber(growthComparedToPreviousMonth)}%
                </p>
              </div>
            </div>
          </div>

          {/* <div className="goal-grid-content">
            <h4>Status</h4>
            <div className="goal-values">
              <p
                className="status-goal-p"
                style={
                  totalValueCurrentMonth - shouldHaveReachedByNow > 0
                    ? { color: "#03ff03" }
                    : { color: "red" }
                }
              >
                {totalValueCurrentMonth - shouldHaveReachedByNow > 0 ? (
                  <FaArrowCircleUp />
                ) : (
                  <FaArrowCircleDown />
                )}
                {formatNumber(amountAheadOrShort)}{" "}
                {totalValueCurrentMonth - shouldHaveReachedByNow > 0
                  ? "ahead"
                  : "short"}{" "}
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="goal-progress-bar">
        <ProgressBar
          current={totalValueCurrentMonth}
          goal={monthlyGoal}
          dailyGoal={dailyGoal}
          daysPassed={daysPassed}
          daysInCurrentMonth={daysInCurrentMonth}
        />
      </div>
    </div>
  );
}
